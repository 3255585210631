<template>
  <div class='wrap between role' :style='`height:${height-12}px`'>
    <div class='head'>
      <el-form :inline="true" class="demo-form-inline" :model="selectRole">
        <el-form-item label="角色名称">
          <el-input v-model="selectRole.roleName" clearable></el-input>
        </el-form-item>
        <el-form-item label="权限字符">
          <el-input v-model="selectRole.roleKey" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click='getRole' icon="el-icon-search">确定</el-button>
        </el-form-item>
      </el-form>
      <div class='btnWrap'>
        <div class='btn' @click="addRole">
          <svg-icon icon-class="add" class='icon'/>
          添加角色
        </div>
      </div>
    </div>
    <div class='primarys' :style='`height:${height-82}px`'>
      <el-table
        :data="roleList"
        :style="`min-width:967px;width:${width-40}px;margin:-1px`"
        :header-cell-style='{"background":"#F5F9FA"}'
        :max-height="height-80"
        border>
        <el-table-column type="index" width="60" align='center' label="序号" :index="indexMethod"></el-table-column>
        <el-table-column prop="roleName" label="角色名称" align="center" min-width='100'></el-table-column>
        <el-table-column prop="roleKey" label="权限字符" align="center" min-width='100'></el-table-column>
        <el-table-column prop="roleSort" label="排序" align="center" min-width='100' sortable></el-table-column>
        <el-table-column prop="status" label="使用状态" align="center" min-width='100'>
          <template slot-scope="scope">
            <div>
              <el-switch
                v-model="scope.row.status"
                @change="statusChange(scope.row)"
                active-value="0"
                inactive-value="1">
              </el-switch>
              <span style='margin-left:5px'>{{scope.row.status==0?'启用':'停止'}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" min-width='150' sortable></el-table-column>
        <el-table-column prop="remark" label="备注" align="center" min-width='150'></el-table-column>
        <el-table-column 
          label="操作" 
          min-width="220" 
          fixed='right'
          align='center'>
          <template slot-scope="scope" v-if="scope.row.roleId !== 1">
            <el-button @click.native.prevent="editRole(scope.row.roleId)" type="text" size="small">编辑</el-button>
            <el-button @click.native.prevent="dataRole(scope.row.roleId)" type="text" size="small">分配权限</el-button>
            <el-button @click.native.prevent="configRole(scope.row.roleId)" type="text" size="small">分配用户</el-button>
            <el-button @click.native.prevent="delRole(scope.row.roleId)" type="text" size="small" class='del'>删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        class='pagination'
        @size-change="handleSizeChange"
        :page-sizes="[15, 30, 45, 60]"
        :total="total"
        :page-size='selectRole.pageSize'
        :current-page='selectRole.pageNum'
        @current-change='pageChange'>
      </el-pagination>
    </div>
    <el-dialog :title="title" :visible.sync="roleShow"
      :close-on-click-modal='false'
      width='780px'
      :custom-class='"dialog-setStyle"'>
      <div class='formWrap'>
        <el-form ref="roleInfo" :model="roleInfo" label-width="80px" :rules='roleRules'>
          <div class='dialog-setWrap'>
            <el-form-item label="角色名称" prop="roleName">
              <el-input v-model="roleInfo.roleName" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="权限字符" prop='roleKey'>
              <el-input v-model="roleInfo.roleKey" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="角色排序"  prop='roleSort'>
              <el-input-number v-model="roleInfo.roleSort" controls-position="right" :min="1" :max="20"></el-input-number>
            </el-form-item>
            <el-form-item label="使用状态" style='width:360px' class='radioWrap'>
              <el-radio-group v-model="roleInfo.status">
                <el-radio
                  v-for="item in statusList"
                  :key="item.dictValue"
                  :label="item.dictValue"
                >{{item.dictLabel}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="菜单权限">
              <el-checkbox v-model="menuExpand" @change="handleCheckedTreeExpand($event, 'menu')">展开/折叠</el-checkbox>
              <el-checkbox v-model="menuNodeAll" @change="handleCheckedTreeNodeAll($event, 'menu')">全选/全不选</el-checkbox>
              <el-checkbox v-model="roleInfo.menuCheckStrictly" @change="handleCheckedTreeConnect($event, 'menu')">父子联动</el-checkbox>
              <el-tree
                class="tree-border"
                :data="menuOptions"
                show-checkbox
                ref="menu"
                node-key="id"
                :check-strictly="!roleInfo.menuCheckStrictly"
                empty-text="加载中，请稍候"
                :props="defaultProps"
              ></el-tree>
            </el-form-item>
            <el-form-item label="备注">
              <el-input type="textarea"  v-model="roleInfo.remark" @input='$forceUpdate()'></el-input>
            </el-form-item>
          </div>
          <el-form-item class='saveBtn'>
            <el-button type="primary" @click="saveRole('roleInfo')">保存</el-button>
            <el-button type="info" @click="roleShow=false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog title="分配数据权限" :visible.sync="deptShow"
      :close-on-click-modal='false'
      width='780px'
      :custom-class='"dialog-setStyle"'>
      <div class='formWrap'>
        <el-form ref="roleInfo" :model="roleInfo" label-width="80px">
          <div class='dialog-setWrap'>
            <el-form-item label="角色名称" prop="roleName">
              <el-input v-model="roleInfo.roleName" @input='$forceUpdate()' disabled></el-input>
            </el-form-item>
            <el-form-item label="权限字符" prop='roleKey'>
              <el-input v-model="roleInfo.roleKey" @input='$forceUpdate()' disabled></el-input>
            </el-form-item>
            <el-form-item label="权限范围" prop='dataScope' class='radioWrap'>
              <el-select v-model="roleInfo.dataScope" @change="dataChange">
                <el-option
                  v-for="item in dataScopeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="菜单权限" v-show="roleInfo.dataScope == 2">
              <el-checkbox v-model="deptExpand" @change="handleCheckedTreeExpand($event, 'dept')">展开/折叠</el-checkbox>
              <el-checkbox v-model="deptNodeAll" @change="handleCheckedTreeNodeAll($event, 'dept')">全选/全不选</el-checkbox>
              <el-checkbox v-model="roleInfo.deptCheckStrictly" @change="handleCheckedTreeConnect($event, 'dept')">父子联动</el-checkbox>
              <el-tree
                class="tree-border"
                :data="deptOptions"
                show-checkbox
                default-expand-all
                ref="dept"
                node-key="id"
                :check-strictly="!roleInfo.deptCheckStrictly"
                empty-text="加载中，请稍候"
                :props="defaultProps"
              ></el-tree>
            </el-form-item>
            <el-form-item label="备注">
              <el-input type="textarea"  v-model="roleInfo.remark" @input='$forceUpdate()'></el-input>
            </el-form-item>
          </div>
          <el-form-item class='saveBtn'>
            <el-button type="primary" @click="saveDept('roleInfo')">保存</el-button>
            <el-button type="info" @click="deptShow=false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog 
      :visible.sync="delTip"
      :close-on-click-modal='false'
      width='400px'
      top='300px'
      :custom-class='"dialog-setStyle"'>
      <div class='tipWrap'>
        <div class='tipTitle'>
          <i class='el-icon-warning'></i>
          温馨提示
        </div>
        <div class='tipContent'>此操作将永久删除该角色信息，请谨慎操作，是否继续？</div>
      </div>
      <div class='saveBtn viewBtn'>
        <el-button type="primary" @click="sendDel">删除</el-button>
        <el-button type="info" @click="delTip=false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../utils/requst.js'
export default {
  props:{
    width:Number,
    height:Number,
    param:Object
  },
  data(){
    return{
      selectRole:{
        pageNum:1,
        pageSize:15,
        orderByColumn:'roleId',
        roleName:'',
        roleKey:''
      },
      roleId:'',
      roleList:[],
      total:0,
      title:'',
      roleInfo:{},
      isAdd:false,
      roleShow:false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      deptShow:false,
      delTip:false,
      dataScopeOptions: [
        {
          value: "1",
          label: "全部数据权限"
        },
        {
          value: "2",
          label: "自定数据权限"
        },
        {
          value: "3",
          label: "本部门数据权限"
        },
        {
          value: "4",
          label: "本部门及以下数据权限"
        },
        {
          value: "5",
          label: "仅本人数据权限"
        }
      ],
      menuOptions: [],
      deptOptions: [],
      statusList:[],
      defaultProps: {
        children: "children",
        label: "label"
      },
      deptInfo:{},
      roleRules:{
        roleName: [
          { required: true, message: "角色名称不能为空", trigger: "blur" }
        ],
        roleKey: [
          { required: true, message: "权限字符不能为空", trigger: "blur" }
        ],
        roleSort: [
          { required: true, message: "角色顺序不能为空", trigger: "blur" }
        ]
      }
    }
  },
  created(){
    if(JSON.stringify(this.param) != "{}"){
      this.selectRole=this.param
    }
    this.getRole()
  },
  methods:{
    getRole(){
      axios.get('/system/role/list',{params:this.selectRole}).then(res=>{
        if(res){
          this.roleList=res.rows;
          this.total=res.total;
          this.getMenu();
          this.getDept()
          this.getStatus();
        }
      })
    },
    getMenu(){
      axios.get('/system/menu/treeselect').then(res=>{
        if(res){
          this.menuOptions=res.data;
        }
      })
    },
    getDept(){
      axios.get('/system/dept/treeselect').then(res=>{
        if(res){
          this.deptOptions=res.data;
        }
      })
    },
    getStatus(){
      axios.get(`/system/dict/data/type/sys_normal_disable`).then(res=>{
        if(res){
          this.statusList=res.data;
        }
      })
    },
    statusChange(row){
      let text = row.status === "0" ? "启用" : "停用";
      this.$confirm(`确定要${text}${row.roleName}的角色吗`, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        axios.put('/system/role/changeStatus',{roleId:row.roleId,status:row.status}).then(data=>{
          if(data){
            this.$message({
              type: 'success',
              message:`${text}成功！`,
              duration:1000
            });
          }
        }).catch(res=>{
          row.status=row.status==='0'?'1':'0'
        }) 
      }).catch(res=>{
        row.status=row.status==='0'?'1':'0'
      })
    },
    addRole(){
      this.title='添加角色信息'
      this.isAdd=true;
      this.roleShow=true;
      this.roleInfo={
        roleSort:1,
        status: "0",
        menuCheckStrictly: true,
        menuIds: [],
      }
      if (this.$refs.menu != undefined) {
        this.$refs.menu.setCheckedKeys([]);
      }
    },
    editRole(roleId){
      axios.get(`/system/menu/roleMenuTreeselect/${roleId}`).then(data=>{
        if(data){
          let checkedKeys=data.checkedKeys;
          axios.get(`/system/role/${roleId}`).then(res=>{
            if(res){
              this.title='修改角色信息';
              this.isAdd=false;
              this.roleShow=true;
              this.roleInfo=res.data;
              this.$nextTick(() => {
                checkedKeys.forEach((v) => {
                  this.$nextTick(()=>{
                    this.$refs.menu.setChecked(v, true ,false);
                  })
                })
              });
            }
          })
        }
      })
    },
    delRole(roleId){
      this.delTip=true;
      this.roleId=roleId;
    },
    sendDel(){
      axios.delete(`/system/role/${this.roleId}`).then(data=>{
        if(data){
          this.getRole()
          this.delTip=false;
          this.$message({
            type: 'success',
            message: '删除成功!',
            duration:1000
          });
        }
      })
    },
    dataChange(value){
      if(value !== '2') {
        this.$refs.dept.setCheckedKeys([]);
      }
    },
    dataRole(roleId){
      axios.get(`/system/dept/roleDeptTreeselect/${roleId}`).then(event=>{
        if(event){
          let checkedKeys = event.checkedKeys;
          axios.get(`/system/role/${roleId}`).then(res=>{
            this.deptShow=true;
            this.roleInfo=res.data;
            this.$nextTick(() => {
              this.$refs.dept.setCheckedKeys(checkedKeys);
            });
          })
        }
      })
    },
    configRole(roleId){
      this.$emit('emitInfo',{
        page:'dispense',
        argument:roleId,
        param:this.selectRole
      })
    },
    saveRole(obj){
      this.$refs[obj].validate((valid)=>{
        if(valid){
          this.roleInfo.menuIds = this.getMenuAllCheckedKeys();
          if(this.isAdd){
            axios.post('/system/role',this.roleInfo).then(data=>{
              if(data){
                this.getRole()
                this.$message({
                  type: 'success',
                  message: '添加成功!',
                  duration:1000
                });
                this.roleShow=false;
              }
            })
          }else{
            axios.put('/system/role',this.roleInfo).then(data=>{
              if(data){
                this.getRole()
                this.$message({
                  type: 'success',
                  message: '修改成功!',
                  duration:1000
                });
                this.roleShow=false;
              }
            })
          }
        }else{
          return false
        }
      })
    },
    saveDept(obj){
      this.$refs[obj].validate((valid)=>{
        if(valid){
          this.roleInfo.deptIds = this.getDeptAllCheckedKeys();
          axios.put('/system/role/dataScope',this.roleInfo).then(data=>{
            if(data){
              this.getRole()
              this.$message({
                type: 'success',
                message: '修改成功!',
                duration:1000
              });
              this.deptShow=false;
            }
          })
        }else{
          return false
        }
      })
    },
     getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getCheckedKeys();
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    getDeptAllCheckedKeys() {
      // 目前被选中的部门节点
      let checkedKeys = this.$refs.dept.getCheckedKeys();
      // 半选中的部门节点
      let halfCheckedKeys = this.$refs.dept.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    handleCheckedTreeExpand(value, type) {
      if (type == 'menu') {
        let treeList = this.menuOptions;
        for (let i = 0; i < treeList.length; i++) {
          this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value;
        }
      } else if (type == 'dept') {
        let treeList = this.deptOptions;
        for (let i = 0; i < treeList.length; i++) {
          this.$refs.dept.store.nodesMap[treeList[i].id].expanded = value;
        }
      }
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value, type) {
      if (type == 'menu') {
        this.$refs.menu.setCheckedNodes(value ? this.menuOptions: []);
      } else if (type == 'dept') {
        this.$refs.dept.setCheckedNodes(value ? this.deptOptions: []);
      }
    },
    // 树权限（父子联动）
    handleCheckedTreeConnect(value, type) {
      if (type == 'menu') {
        this.roleInfo.menuCheckStrictly = value ? true: false;
      } else if (type == 'dept') {
        this.roleInfo.deptCheckStrictly = value ? true: false;
      }
    },
    indexMethod(index){
      return (this.selectRole.pageNum-1)*this.selectRole.pageSize+index+1
    },
    pageChange(val){
      this.selectRole.pageNum=val;
      this.getRole()
    },
    handleSizeChange(val){
      this.selectRole.pageSize=val;
      this.getRole()
    }
  }
}
</script>

<style lang="scss" scoped>
.role{
  .tree-border{
    border: 1px solid #e5e6e7;
    width: 660px;
    border-radius: 4px;
    box-sizing: border-box;
  }
}
</style>