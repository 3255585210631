<template>
  <div class='wrap dictionary' :style='`height:${height-12}px`'>
    <div class='head'>
      <el-form :inline="true" class="demo-form-inline" :model="selectDict">
        <el-form-item label="字典名称">
          <el-input v-model='selectDict.dictName'></el-input>
        </el-form-item>
        <el-form-item label="字典类型">
          <el-input v-model='selectDict.dictType'></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click='getDictionary' icon="el-icon-search">确定</el-button>
        </el-form-item>
      </el-form>
      <div class='btnWrap'>
        <div class='btn' @click="addDictionary">
          <svg-icon icon-class="add" class='icon'/>
          添加字典
        </div>
        <div class='btn' @click="refreshCache">
          <svg-icon icon-class="clear" class='icon'/>
          清除缓存
        </div>
      </div>
    </div>
    <div class='primarys' :style='`height:${height-82}px`'>
      <el-table
        :data="dictionaryList"
        ref="dictionary-table"
        :style="`min-width:967px;width:${width-40}px;margin:-1px`"
        :header-cell-style='{"background":"#F5F9FA"}'
        :max-height="height-80"
        id='dictionaryExcel'
        border>
        <el-table-column type="index" width="60" align='center' label="序号" :index="indexMethod"></el-table-column>
        <el-table-column prop="dictName" label="字典名称" align="center" min-width='100' :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="字典类型" align="center" min-width='100' :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span class="link-type" @click="setDictionary(scope.row.dictType)">{{scope.row.dictType}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="使用状态" align="center" min-width='100' sortable>
          <template slot-scope="scope">
            <span :class='scope.row.status=="0"?"success":"error"'>{{scope.row.status=='0'?"正常":"停用"}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center" min-width='150' :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" min-width='150'></el-table-column>
        <el-table-column 
          label="操作" 
          min-width="100" 
          fixed='right'
          align='center'>
          <template slot-scope="scope">
            <el-button @click.native.prevent="editDictionary(scope.row.dictId)" type="text" size="small">修改</el-button>
            <el-button @click.native.prevent="delDictionary(scope.row.dictId)" type="text" class='del' size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        class='pagination'
        :total="total"
        @size-change="handleSizeChange"
        :page-sizes="[15, 30, 45, 60]"
        :page-size='selectDict.pageSize'
        :current-page='selectDict.pageNum'
        @current-change='pageChange'>
      </el-pagination>
    </div>
    <el-dialog :title="title" :visible.sync="dictionaryShow"
      :close-on-click-modal='false'
      width='780px'
      :custom-class='"dialog-setStyle"'>
      <div class='formWrap'>
        <el-form ref="dictionaryInfo" :model="dictionaryInfo" label-width="80px" :rules="dictionaryRole">
          <div class='dialog-setWrap'>
            <el-form-item label="字典名称" prop="dictName">
              <el-input v-model="dictionaryInfo.dictName" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="字典类型" prop='dictType'>
              <el-input v-model="dictionaryInfo.dictType" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="系统内置" class='radioWrap'>
              <el-radio-group v-model="dictionaryInfo.status">
                <el-radio label="0">正常</el-radio>
                <el-radio label="1">停用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="备注">
               <el-input v-model="dictionaryInfo.remark" @input='$forceUpdate()'></el-input>
            </el-form-item>
          </div>
          <el-form-item class='saveBtn'>
            <el-button type="primary" @click="saveDictionary('dictionaryInfo')">保存</el-button>
            <el-button type="info" @click="dictionaryShow=false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog 
      :visible.sync="delTip"
      :close-on-click-modal='false'
      width='400px'
      top='300px'
      :custom-class='"dialog-setStyle"'>
      <div class='tipWrap'>
        <div class='tipTitle'>
          <i class='el-icon-warning'></i>
          温馨提示
        </div>
        <div class='tipContent'>此操作将永久删除该字典信息, 是否继续？</div>
      </div>
      <div class='saveBtn viewBtn'>
        <el-button type="primary" @click="sendDel">删除</el-button>
        <el-button type="info" @click="delTip=false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../utils/requst.js'
export default {
  props:{
    width:Number,
    height:Number,
    param:Object
  },
  data(){
    return{
      selectDict:{
        pageNum:1,
        pageSize:15,
        isAsc:'desc',
        orderByColumn:'dictId',
        dictName:'',
        dictType:''
      },
      dictId:'',
      dictionaryList:[],
      total:0,
      title:"",
      dictionaryInfo:{
        status:'0'
      },
      isAdd:false,
      dictionaryShow:false,
      delTip:false,
      dictionaryRole:{
        dictName: [
          { required: true, message: "字典名称不能为空", trigger: "blur" }
        ],
        dictType: [
          { required: true, message: "字典类型不能为空", trigger: "blur" }
        ]
      }
    }
  },
  created(){
    if(JSON.stringify(this.param) != "{}"){
      this.selectDict=this.param
    }
    this.getDictionary();
  },
  methods:{
    getDictionary(){
      axios.get('/system/dict/type/list',{params:this.selectDict}).then(res=>{
        if(res){
          this.total=res.total;
          this.dictionaryList=res.rows;
        }
      })
    },
    addDictionary(){
      this.isAdd=true;
      this.dictionaryShow=true;
      this.title='添加字典信息'
    },
    editDictionary(dictId){
      axios.get(`/system/dict/type/${dictId}`).then(res=>{
        if(res){
          this.isAdd=false;
          this.dictionaryShow=true;
          this.title='修改字典信息';
          this.dictionaryInfo=res.data;
        }
      })
    },
    delDictionary(dictId){
      this.delTip=true;
      this.dictId=dictId;
    },
    sendDel(){
      axios.delete(`/system/dict/type/${this.dictId}`).then(data=>{
        if(data){
          this.getDictionary();
          this.delTip=false;
          this.$message({
            type: 'success',
            message: '删除成功!',
            duration:1000
          });
        }
      })
    },
    refreshCache(){
      axios.delete('/system/dict/type/refreshCache').then(data=>{
        if(data){
          this.$message({
            type: 'success',
            message: '清除成功!',
            duration:1000
          });
        }
      })
    },
    saveDictionary(obj){
      this.$refs[obj].validate((valid)=>{
        if(valid){
          if(this.isAdd){
            axios.post('/system/dict/type',this.dictionaryInfo).then(data=>{
              if(data){
                this.getDictionary()
                this.$message({
                  type: 'success',
                  message: '添加成功!',
                  duration:1000
                });
                this.dictionaryShow=false;
              }
            })
          }else{
            axios.put('/system/dict/type',this.dictionaryInfo).then(data=>{
              if(data){
                this.getDictionary()
                this.$message({
                  type: 'success',
                  message: '修改成功!',
                  duration:1000
                });
                this.dictionaryShow=false;
              }
            })
          }
        }else{
          return false
        }
      })
    },
    setDictionary(dictType){
      this.$emit('emitInfo',{
        page:'configPage',
        argument:dictType,
        param:this.selectDict
      })
    },
    indexMethod(index){
      return (this.selectDict.pageNum-1)*this.selectDict.pageSize+index+1
    },
    pageChange(val){
      this.selectDict.pageNum=val;
      this.getDictionary()
    },
    handleSizeChange(val){
      this.selectDict.pageSize=val;
      this.getDictionary()
    }
  }
}
</script>

<style lang="scss" scoped>
.dictionary{
  .link-type{
    color: #3562ff;
    cursor: pointer;
  }
  .dialog-setStyle{
    .dialog-setWrap{
      justify-content: space-between !important;
    }
  }
}
</style>

