<template>
  <div class="login">
    <div class='wrap'>
      <div class='wareAreaName'>智慧粮库管理平台</div>
      <div class='login'>
        <div class='loginInfo'>
          <div class='loginTitle'>
            <div class='mainTitle'>智慧粮库云平台</div>
            <div class='subTitle'>INTELLIGENT GRANARY</div>
          </div>
          <div class='setInput'>
            <div class='loginName'>账户登录</div>
            <div>
              <el-input
                placeholder="请输入账号"
                v-model='loginForm.username'
                prefix-icon="el-icon-user-solid">
              </el-input>
              <el-input
                placeholder="请输入密码"
                prefix-icon="el-icon-lock"
                type="password"
                v-model='loginForm.password'
                autocomplete="new-password">
              </el-input>
            </div>
            <div class='setVerCode'>
              <el-input
                v-model="loginForm.code" 
                @keyup.enter.native='handleLogin'
                placeholder="请输入验证码">
              </el-input>
              <span class="verCode" @click="getCode()"><img :src="codeUrl" alt=""></span>
            </div>
            <div class='hanelLogin' @click="handleLogin">登录</div>
          </div>
        </div>
        <div class='loginImgWrap'>
          <div class='login_img'></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../utils/requst.js'
export default {
  data() {
    return {
      codeUrl: "",
      loginForm: {
        username: "",
        password: "",
        code: "",
        uuid: ""
      },
      redirect: undefined,
    };
  },
  created(){
    this.getCode();
    this.getUser();
  },
  methods:{
    getCode(){
      axios.get('/captchaImage',{getcode:true}).then(data=>{
        this.codeUrl = "data:image/gif;base64," + data.img;
        this.loginForm.uuid = data.uuid;
      })
    },
    getUser(){
      const userAdmin =JSON.parse(localStorage.getItem('userAdmin'));
      if(userAdmin){
        this.loginForm.username=userAdmin.username;
        this.loginForm.password=userAdmin.password;
      }
    },
    handleLogin(){
      let that=this;
      axios.post('login',this.loginForm,{isLogin:true}).then(data=>{
        if(data.code==200){
          localStorage.setItem('userAdmin', JSON.stringify(that.loginForm))
          localStorage.setItem('tokenAdmin', data.token)
          const path = this.$route.query.returnURL
          this.$router.push(path ? path : '/')
        }else{
          that.$message({
            message:data.msg,
            type:'error',
            duration:1000,
          })
          that.getCode()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login{
  width: 100%;
  height: 100%;
  .wrap{
    width: 100%;
    height: 100%;
    background-image: url("../assets/bg/login_bg.png");
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .wareAreaName{
      position: absolute;
      font-size:96px;
      top: 30px;
      font-weight: bold;
      text-shadow: 10px 10px 5px rgba(0,0,0,0.3);
      font-family: '楷体';
      color: rgba(255,255,255,.8)
    }
    .login{
      width: 1100px;
      height: 600px;
      background: #ffffff;
      box-shadow: 0px 4px 42px 0px rgba(3,51,110,.35);
      border-radius: 14px;
      position: relative;
      overflow: hidden;
      .loginInfo{
        background-color: white;
        height: 600px;
        width: 514px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        .loginTitle{
          width: 360px;
          margin-top: 60px;
          .mainTitle{
            height: 42px;
            font-size: 36px;
            font-family:'pangmen';
            font-weight: 400;
            color: #242424;
            line-height: 42px;
          }
          .subTitle{
            width: 269px;
            font-family: 'PingFang';
            font-weight: 100;
            color: rgba(0,0,0,0.3);
            line-height: 28px;
            font-size: 24px
          }
        }
        .setInput{
          width: 360px;
          height: 236px;
          margin-top: 53px;
          .loginName{
            font-size: 18px;
            font-weight: bold;
            color: #1D2129
          }
          ::v-deep .el-input{
            margin-top: 20px;
          }
          ::v-deep .el-input--small .el-input__inner{
            height: 40px;
          }
          .setVerCode{
            position: relative;
            .verCode{ 
              height: 40px;
              display: inline-block;
              width: 107px;
              position: absolute;
              right: 0;
              top: 20px;
              cursor: pointer;
              img{
                height: 40px;
              }
            }
          }
          .hanelLogin{
            height: 40px;
            font-weight: 400;
            text-align: center;
            color: white;
            background-color: #2878FF;
            line-height: 40px;
            margin-top: 20px;
            cursor: pointer;
          }
        }
      }
      .loginImgWrap{
        position: absolute;
        right: 0;
        top: 0;
        height: 605px;
        width: 600px;
        background: linear-gradient(158deg, #B8C7FF 0%, rgba(190,218,255,0) 100%);
        background-image: url("../assets/bg/login.png");
        background-position: center center
      }
    }
  }
}
</style>
