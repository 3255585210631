<template>
  <div class='wrap between' :style='`height:${height-12}px`'>
    <div class='head'>
      <el-form :inline="true" class="demo-form-inline" :model="selectAgent">
        <el-form-item label="用户账号">
          <el-input v-model="selectAgent.AgentName" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input v-model="selectAgent.phonenumber" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click='getAgentList' icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
      <div class='btnWrap'>
        <div class='btn' @click="addAgent">
          <svg-icon icon-class="add" class='icon'/>
          添加
        </div>
      </div>
    </div>
    <div class='primarys' :style='`height:${height-82}px`'>
      <el-table
        :data="agentList"
        :style="`min-width:967px;width:${width-40}px;margin:-1px`"
        :header-cell-style='{"background":"#F5F9FA"}'
        :max-height="height-80"
        border>
        <el-table-column type="index" width="60" align='center' label="序号" :index="indexMethod"></el-table-column>
        <el-table-column prop="deptName" label="库区名称" align="center" min-width='100'></el-table-column>
        <el-table-column prop="leader" label="库区负责人" align="center" min-width='100'></el-table-column>
        <el-table-column prop="phone" label="联系电话" align="center" min-width='100'></el-table-column>
        <el-table-column prop="email" label="邮箱" align="center" min-width='100'></el-table-column>
        <el-table-column prop="company" label="技术支持公司" align="center" min-width='100'></el-table-column>
        <el-table-column prop="salesPhone" label="售后电话" align="center" min-width='100'></el-table-column>
        <el-table-column 
          label="操作" 
          min-width="150" 
          fixed='right'
          align='center'>
          <template slot-scope="scope">
            <el-button @click.native.prevent="editAgent(scope.row.deptId)" type="text" size="small">编辑</el-button>
            <el-button @click.native.prevent="delAgent(scope.row.deptId)" class='del' type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="pageChange"
        :current-page='selectAgent.pageNum'
        :page-sizes="[15, 30, 45, 60]"
        :page-size="selectAgent.pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        class='pagination'
        :total="total">
      </el-pagination>
    </div>
    <el-dialog :title="title" :visible.sync="agentShow"
      :close-on-click-modal='false'
      width='820px'
      :custom-class='"dialog-setStyle"'>
      <div class='formWrap'>
        <el-form ref="agentInfo" :model="agentInfo" label-width="100px">
          <div class='dialog-setWrap'>
            <el-form-item label="库区名称" prop="deptName">
              <el-input v-model="agentInfo.deptName" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="库区负责人" prop='leader'>
              <el-input v-model="agentInfo.leader" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop='phone'>
              <el-input v-model="agentInfo.phone" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop='email'>
              <el-input v-model="agentInfo.email" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="技术支持公司" prop='company'>
              <el-input v-model="agentInfo.company" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="售后电话" prop='salesPhone'>
              <el-input v-model="agentInfo.salesPhone" @input='$forceUpdate()'></el-input>
            </el-form-item>
          </div>
          <el-form-item class='saveBtn'>
            <el-button type="primary" @click="saveAgent('agentInfo')">保存</el-button>
            <el-button type="info" @click="agentShow=false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog 
      :visible.sync="delTip"
      :close-on-click-modal='false'
      width='400px'
      top='300px'
      :custom-class='"dialog-setStyle"'>
      <div class='tipWrap'>
        <div class='tipTitle'>
          <i class='el-icon-warning'></i>
          温馨提示
        </div>
        <div class='tipContent'>此操作将永久删除该用户信息, 是否继续？</div>
      </div>
      <div class='saveBtn viewBtn'>
        <el-button type="primary" @click="sendDel">删除</el-button>
        <el-button type="info" @click="delTip=false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../utils/requst.js'
export default {
  props:{
    width:Number,
    height:Number
  },
  data(){
    return{
      selectAgent:{
        pageNum:1,
        pageSize:15,
       
      },
      agentList:[],
      total:0,
      title:'',
      agentInfo:{},
      isAdd:false,
      agentShow:false,
      delTip:false
    }
  },
  created(){
    this.getAgentList()
  },
  methods:{
    getAgentList(){
      axios.get('/system/copyright/list',{params:this.selectAgent}).then(res=>{
        if(res){
          this.agentList=res.rows;
          this.total=res.total;
        }
      })
    },
    addAgent(){
      this.isAdd=true;
      this.agentShow=true;
      this.title='添加库区信息';
      this.agentInfo={
       
      }
    },
    editAgent(deptId){
      axios.get(`/system/copyright/${deptId}`).then(res=>{
        if(res){
          this.isAdd=false;
          this.agentShow=true;
          this.title='修改库区信息';
          this.agentInfo=res.data;
        }
      })
    },
    delAgent(deptId){
      this.delTip=true;
      this.deptId=deptId;
    },
    sendDel(){
      axios.delete(`/system/copyright/${this.deptId}`).then(data=>{
        if(data){
          this.getAgentList()
          this.delTip=false;
          this.$message({
            type: 'success',
            message: '删除成功!',
            duration:1000
          });
        }
      })
    },
    saveAgent(obj){
      this.$refs[obj].validate((valid)=>{
        if(valid){
          if(this.isAdd){
            axios.post('/system/copyright',this.agentInfo).then(data=>{
              if(data){
                this.getAgentList()
                this.$message({
                  type: 'success',
                  message: '添加成功!',
                  duration:1000
                });
                this.agentShow=false;
              }
            })
          }else{
            axios.put('/system/copyright',this.agentInfo).then(data=>{
              if(data){
                this.getAgentList()
                this.$message({
                  type: 'success',
                  message: '修改成功!',
                  duration:1000
                });
                this.agentShow=false;
              }
            })
          }
        }else{
          return false
        }
      })
    },
    indexMethod(index){
      return (this.selectAgent.pageNum-1)*this.selectAgent.pageSize+index+1
    },
    pageChange(val){
      this.selectAgent.pageNum=val;
      this.getAgentList()
    },
    handleSizeChange(val){
      this.selectAgent.pageSize=val;
      this.getAgentList()
    },
  }
}
</script>

<style lang="scss" scoped>

</style>


