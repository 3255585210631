<template>
  <div class='wrap taskSetTime' :style='`height:${height-12}px`'>
    <div class='head'>
      <el-form :inline="true" class="demo-form-inline" :model="selectJob">
        <el-form-item label="任务名称">
          <el-input v-model="selectJob.jobName" clearable></el-input>
        </el-form-item>
        <el-form-item label="任务组名">
          <el-select v-model="selectJob.jobGroup" clearable placeholder="请选择" @change='$forceUpdate()'>
            <el-option
              v-for="item in dict.type.sys_job_group"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click='getjobList' icon="el-icon-search">确定</el-button>
        </el-form-item>
      </el-form>
      <div class='btnWrap'>
        <div class='btn' @click="addJob">
          <svg-icon icon-class="add" class='icon'/>
          添加任务
        </div>
        <div class='btn' @click="jobLog">
          <svg-icon icon-class="log" class='icon'/>
          日志
        </div>
      </div>
    </div>
    <div class='primarys' :style='`height:${height-82}px`'>
      <el-table
        :data="jobList"
        :style="`min-width:967px;width:${width-40}px;margin:-1px`"
        :header-cell-style='{"background":"#F5F9FA"}'
        :max-height="height-80"
        border>
        <el-table-column type="index" width="60" align='center' label="序号" :index="indexMethod"></el-table-column>
        <el-table-column prop="jobName" label="任务名称" align="center" min-width='100' :show-overflow-tooltip="true" ></el-table-column>
        <el-table-column label="任务分组" align="center" min-width='100' prop="jobGroup" sortable>
          <template slot-scope="scope">
            <dict-tag :options="dict.type.sys_job_group" :value="scope.row.jobGroup"/>
          </template>
        </el-table-column>
        <el-table-column prop="invokeTarget" :show-overflow-tooltip="true" label="调用方法" align="center" min-width='130'></el-table-column>
        <el-table-column label="cron执行表达式" align="center" prop="cronExpression" :show-overflow-tooltip="true" min-width='100'></el-table-column>
        <el-table-column label="状态" align="center" min-width='120'>
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              @change="statusChange(scope.row)"
              active-value="0"
              inactive-value="1">
            </el-switch>
            <span style='margin-left:5px'>{{scope.row.status==0?'启用':'停止'}}</span>
          </template>
        </el-table-column>
        <el-table-column 
          label="操作" 
          min-width="250" 
          fixed='right'
          align='center'>
          <template slot-scope="scope">
            <el-button @click.native.prevent="editJob(scope.row.jobId)" type="text" size="small">修改</el-button>
            <el-button @click.native.prevent="viewJob(scope.row.jobId)" type="text" size="small">查看</el-button>
            <el-button @click.native.prevent="executeJob(scope.row)" type="text" size="small">执行</el-button>
            <el-button @click.native.prevent="delJob(scope.row.jobId)" type="text" class='del' size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size='selectJob.pageSize'
        :page-sizes="[15, 30, 45, 60]"
        :current-page='selectJob.pageNum'
        @size-change="handleSizeChange"
        @current-change='pageChange'
        class='pagination'>
      </el-pagination>
    </div>
    <el-dialog 
      :title="title" 
      :visible.sync="jobShow" 
      :close-on-click-modal='false'
      :custom-class='"dialog-setStyle"'
      width="840px">
      <div class='formWrap'>
        <el-form ref="jobInfo" label-width="110px" :model="jobInfo" :rules="jobRole">
          <div class='dialog-setWrap'>
            <el-form-item label="任务名称" prop="jobName">
              <el-input v-model="jobInfo.jobName" placeholder="请输入任务名称" />
            </el-form-item>
            <el-form-item label="任务分组" prop="jobGroup">
              <el-select v-model="jobInfo.jobGroup" placeholder="请选择任务分组">
                <el-option
                  v-for="item in dict.type.sys_job_group"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="invokeTarget" class='methods'>
              <span slot="label">
                调用方法
                <el-tooltip placement="top">
                  <div slot="content">
                    Bean调用示例：ryTask.ryParams('ry')
                    <br />Class类调用示例：com.ruoyi.quartz.task.RyTask.ryParams('ry')
                    <br />参数说明：支持字符串，布尔类型，长整型，浮点型，整型
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </span>
              <el-input v-model="jobInfo.invokeTarget" placeholder="请输入调用目标字符串" />
            </el-form-item>
            <el-form-item label="cron表达式" prop="cronExpression" class='methods'>
              <el-input v-model="jobInfo.cronExpression" placeholder="请输入cron执行表达式">
                <template slot="append">
                  <el-button type="primary" @click="setCron">
                    生成表达式
                    <i class="el-icon-time el-icon--right"></i>
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="执行策略" prop="misfirePolicy">
              <el-select v-model="jobInfo.misfirePolicy">
                <el-option label="立即执行" value="1"></el-option>
                <el-option label="执行一次" value="2"></el-option>
                <el-option label="放弃执行" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否并发" prop="concurrent" class='radioWrap' style='width:390px'>
              <el-radio-group v-model="jobInfo.concurrent" size="small">
                <el-radio label="0">允许</el-radio>
                <el-radio label="1">禁止</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="任务状态" class='radioWrap'> 
              <el-radio-group v-model="jobInfo.status">
                <el-radio
                  v-for="item in dict.type.sys_job_status"
                  :key="item.value"
                  :label="item.value"
                >{{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <el-form-item class='saveBtn'>
            <el-button type="primary" @click="saveJob('jobInfo')">保存</el-button>
            <el-button type="info" @click="jobShow=false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog title="任务详细信息" :visible.sync="jobView"
      :close-on-click-modal='false'
      width='740px'
      :custom-class='"dialog-setStyle"'>
      <div class='viewWrap'>
        <div class='dialog-descriptions'>
          <el-descriptions :column="2" border>
            <el-descriptions-item label="任务名称" label-class-name="myLabel" content-class-name="myContent">{{jobInfo.jobName}}</el-descriptions-item>
            <el-descriptions-item label="任务分组" label-class-name="myLabel" content-class-name="myContent"><dict-tag :options="dict.type.sys_job_group" :value="jobInfo.jobGroup"/></el-descriptions-item>
            <el-descriptions-item label="调用方法" :span="2" label-class-name="myLabel" content-class-name="myContent">{{jobInfo.invokeTarget}}</el-descriptions-item>
            <el-descriptions-item label="cron表达式" label-class-name="myLabel" content-class-name="myContent">{{jobInfo.cronExpression}}</el-descriptions-item>
            <el-descriptions-item label="执行策略" label-class-name="myLabel" content-class-name="myContent">{{jobInfo.misfirePolicy=='1'?"立即执行":(jobInfo.misfirePolicy=='2'?"执行一次":"放弃执行")}}</el-descriptions-item>
            <el-descriptions-item label="是否并发" label-class-name="myLabel" content-class-name="myContent">{{jobInfo.concurrent=='1'?"禁止":"允许"}}</el-descriptions-item>
            <el-descriptions-item label="任务状态" label-class-name="myLabel" content-class-name="myContent">{{jobInfo.status=='0'?"启用":"停止"}}</el-descriptions-item>
          </el-descriptions>       
        </div>
        <div class='saveBtn viewBtn'>
          <el-button type="primary" @click="jobView=false">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog 
      title="Cron表达式生成器" 
      :visible.sync="cronShow" 
      :close-on-click-modal='false'
      width='740px'
      top='30px'
      :custom-class='"dialog-setStyle"'>
      <div class='viewWrap'>
        <div>
          <crontab @hide="cronShow=false" @fill="crontabFill" :expression="cronInfo"></crontab>
        </div>
      </div>
    </el-dialog>
    <el-dialog 
      :visible.sync="delTip"
      :close-on-click-modal='false'
      width='400px'
      top='300px'
      :custom-class='"dialog-setStyle"'>
      <div class='tipWrap'>
        <div class='tipTitle'>
          <i class='el-icon-warning'></i>
          温馨提示
        </div>
        <div class='tipContent'>此操作将永久删除该定时任务信息,请谨慎操作, 是否继续？</div>
      </div>
      <div class='saveBtn viewBtn'>
        <el-button type="primary" @click="sendDel">删除</el-button>
        <el-button type="info" @click="delTip=false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../utils/requst.js'
import Crontab from '@/components/Crontab'
export default {
  props:{
    width:Number,
    height:Number,
    param:Object
  },
  dicts:['sys_job_group','sys_job_status'],
  components: { Crontab },
  data(){
    return{
      selectJob:{
        pageNum:1,
        pageSize:15,
        jobName: '',
        jobGroup: ''
      },
      total:0,
      jobId:null,
      jobList:[],
      jobInfo:{},
      cronInfo:'',
      title:'',
      jobShow:false,
      jobView:false,
      isAdd:false,
      cronShow:false,
      delTip:false,
      jobRole:{
        jobName: [
          { required: true, message: "任务名称不能为空", trigger: "blur" }
        ],
        invokeTarget: [
          { required: true, message: "调用目标字符串不能为空", trigger: "blur" }
        ]
      }
    }
  },
  created(){
    if(JSON.stringify(this.param) != "{}"){
      this.selectJob=this.param
    }
    this.getjobList()
  },
  methods:{
    getjobList(){
      axios.get('/monitor/job/list',{params:this.selectJob}).then(res=>{
        if(res){
          this.jobList=res.rows;
          this.total=res.total;
        }
      })
    },
    addJob(){
      this.title='添加任务信息';
      this.isAdd=true;
      this.jobShow=true;
      this.jobInfo={
        misfirePolicy: '1',
        concurrent: '1',
        status: "0"
      }
    },
    editJob(jobId){
      axios.get(`/monitor/job/${jobId}`).then(res=>{
        if(res){
          this.title='修改任务信息';
          this.jobShow=true;
          this.isAdd=false;
          this.jobInfo=res.data;
        }
      })
    },
    delJob(jobId){
      this.delTip=true;
      this.jobId=jobId;
    },
    sendDel(){
      axios.delete(`/monitor/job/${this.jobId}`).then(data=>{
        if(data){
          this.getjobList();
          this.delTip=false;
          this.$message({
            type: 'success',
            message: '删除成功!',
            duration:1000
          });
        }
      })
    },
    jobLog(){
      this.$emit('emitInfo',{
        page:'taskLog',
        argument:'jobId',
        param:this.selectJob
      })
    },
    viewJob(jobId){
      axios.get(`/monitor/job/${jobId}`).then(res=>{
        if(res){
          this.jobView=true;
          this.jobInfo=res.data;
        }
      })
    },
    executeJob(row){
      axios.put('/monitor/job/run',{jobId:row.jobId,jobGroup:row.jobGroup}).then(data=>{
        if(data){
          this.$message({
            type: 'success',
            message: `执行成功!`,
            duration:1000
          });
        }
      })
    },
    saveJob(obj){
      this.$refs[obj].validate((valid)=>{
        if(valid){
          if(this.isAdd){
            axios.post('/monitor/job',this.jobInfo).then(data=>{
              if(data){
                this.getjobList();
                this.$message({
                  type: 'success',
                  message: '添加成功!',
                  duration:1000
                });
                this.jobShow=false;
              }
            })
          }else{
            axios.put('/monitor/job',this.jobInfo).then(data=>{
              if(data){
                this.getjobList();
                this.$message({
                  type: 'success',
                  message: '修改成功!',
                  duration:1000
                });
                this.jobShow=false;
              }
            })
          }
        }else{
          return false
        }
      })
    },
    statusChange(row){
      let text = row.status === "0" ? "启用" : "停用";
      this.$confirm(`确定要${text}${row.jobName}的任务吗?`, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        axios.put('/monitor/job/changeStatus',{jobId:row.jobId,status:row.status}).then(data=>{
          if(data){
            this.$message({
              type: 'success',
              message: `${text}成功!`,
              duration:1000
            });
          }
        })
      }).catch((res)=>{
        row.status = row.status === "0" ? "1" : "0";
      })
    },
    setCron(){
      this.cronInfo=this.jobInfo.cronExpression;
      this.cronShow=true;
    },
    crontabFill(value) {
      this.jobInfo.cronExpression = value;
    },
    indexMethod(index){
      return (this.selectJob.pageNum-1)*this.selectJob.pageSize+index+1
    },
    pageChange(val){
      this.selectJob.pageNum=val;
      this.getjobList()
    },
    handleSizeChange(val){
      this.selectJob.pageSize=val;
      this.getjobList()
    },
  }
}
</script>

<style>

</style>