<template>
  <div class='wrap user' :style='`height:${height+30}px`'>
    <div class='head'>
      <el-form :inline="true" class="demo-form-inline" :model="selectUser">
        <el-form-item label="用户账号">
          <el-input v-model="selectUser.userName" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input v-model="selectUser.phonenumber" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click='getUser' icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
      <div class='btnWrap'>
        <div class='btn' @click="addAgent">
          <svg-icon icon-class="add" class='icon'/>
          添加代理商
        </div>
        <div class='btn' @click="addUser">
          <svg-icon icon-class="add" class='icon'/>
          添加用户
        </div>
        <div class='btn' @click="addDept">
          <svg-icon icon-class="add" class='icon'/>
          添加库区
        </div>
      </div>
    </div>
    <div class="userMain">
      <div class="nav" :style='`max-height:${height-80}px`'>
        <el-tree
          :data="deptList"
          :props="defaultProps"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          ref="tree"
          node-key="id"
          default-expand-all
          highlight-current
          @node-click="handleNodeClick"
          @node-contextmenu='setOption'
        />
      </div>
      <div class='primarys' :style='`height:${height-82}px`'>
        <el-table
          :data="userList"
          ref="user-table"
          :style="`min-width:967px;width:${width-300}px;margin:-1px`"
          :header-cell-style='{"background":"#F5F9FA"}'
          :max-height="height-80"
          border>
          <el-table-column type="index" width="60" align='center' label="序号" :index="indexMethod"></el-table-column>
          <el-table-column prop="nickName" label="用户名称" align="center" :show-overflow-tooltip="true" min-width='100'></el-table-column>
          <el-table-column prop="userName" label="用户账号" align="center" min-width='100' :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="dept.deptName" label="所属库区" align="center" min-width='100' :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="phonenumber" label="联系电话" align="center" min-width='100' :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="email" label="邮箱" align="center" min-width='100'></el-table-column>
          <el-table-column prop="status" label="使用状态" align="center" min-width='100' sortable>
            <template slot-scope="scope">
              <span :class='scope.row.status=="0"?"blue":"offLine"'>{{scope.row.status=='0'?"正常":"停用"}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center" min-width='150'></el-table-column>
          <el-table-column 
            label="操作" 
            min-width="150" 
            fixed='right'
            align='center'>
            <template slot-scope="scope" v-if="scope.row.userId !== 1">
              <el-button @click.native.prevent="editUser(scope.row.userId)" type="text" size="small">编辑</el-button>
              <el-button @click.native.prevent="resetPassword(scope.row)" type="text" size="small">密码重置</el-button>
              <el-button @click.native.prevent="delUser(scope.row.userId)" class='del' type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageChange"
          :current-page='selectUser.pageNum'
          :page-sizes="[15, 30, 45, 60]"
          :page-size="selectUser.pageSize"
          background
          layout="total, sizes, prev, pager, next, jumper"
          class='pagination'
          :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="userShow"
      :close-on-click-modal='false'
      width='780px'
      :custom-class='"dialog-setStyle"'>
      <div class='formWrap'>
        <el-form ref="userInfo" :model="userInfo" label-width="80px" :rules='userRules'>
          <div class='dialog-setWrap'>
            <el-form-item label="用户名称" prop="nickName">
              <el-input v-model="userInfo.nickName" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="所属库区">
              <treeselect 
                v-model="userInfo.deptId" 
                :options="deptList" 
                placeholder="选择所属库区" />
            </el-form-item>
            <el-form-item label="用户账号" v-if="isAdd" prop='userName'>
              <el-input v-model="userInfo.userName" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="用户密码" v-if="isAdd" prop='password'>
              <el-input v-model="userInfo.password" @input='$forceUpdate()' autocomplete="new-password" type="password" maxlength="20" show-password></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-select v-model="userInfo.sex" placeholder="请选择" @change='$forceUpdate()'>
                <el-option
                  v-for="item in sexList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="角色">
              <el-select v-model="userInfo.roleIds" multiple placeholder="请选择" @change='$forceUpdate()'>
                <el-option
                  v-for="item in roles"
                  :key="item.roleId"
                  :label="item.roleName"
                  :value="item.roleId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="使用状态" class='radioWrap' style='width:360px'>
              <el-radio-group v-model="userInfo.status">
                <el-radio label="0">正常</el-radio>
                <el-radio label="1">停用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="联系电话" prop='phonenumber'>
              <el-input v-model="userInfo.phonenumber" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop='email'>
              <el-input v-model="userInfo.email" @input='$forceUpdate()'></el-input>
            </el-form-item>
          </div>
          <el-form-item class='saveBtn'>
            <el-button type="primary" @click="saveUser('userInfo')">保存</el-button>
            <el-button type="info" @click="userShow=false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="departmentShow"
      :close-on-click-modal='false'
      width='860px'
      :custom-class='"dialog-setStyle"'>
      <div class='formWrap'>
        <el-form ref="departmentInfo" :model="departmentInfo" label-width="120px" :rules="departmentRole">
          <div class='dialog-setWrap'>
            <el-form-item label="上级公司/库区" prop="parentId">
              <treeselect 
              v-model="departmentInfo.parentId" 
              :options="deptList"
               placeholder="选择上级公司/库区"/>
            </el-form-item>
            <el-form-item :label="departmentInfo.parentId=='100'?'代理商':'库区名称'" prop='deptName'>
              <el-input v-model="departmentInfo.deptName" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <!-- <el-form-item label="库区排序" prop='orderNum'>
              <el-input-number v-model="departmentInfo.orderNum" controls-position="right" :min="departmentInfo.parentId!= 0?1:0" :max="20"></el-input-number>
            </el-form-item> -->
            <el-form-item label="负责人" prop='leader'>
              <el-input v-model="departmentInfo.leader" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop='phone'>
              <el-input v-model="departmentInfo.phone" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop='email'>
              <el-input v-model="departmentInfo.email" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="库区状态"  class='radioWrap' style='width:360px'>
              <el-radio-group v-model="departmentInfo.status">
                <el-radio label="0">正常</el-radio>
                <el-radio label="1">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <el-form-item class='saveBtn'>
            <el-button type="primary" @click="saveDepartment('departmentInfo')">保存</el-button>
            <el-button type="info" @click="departmentShow=false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog 
      :visible.sync="delTip"
      :close-on-click-modal='false'
      width='400px'
      top='300px'
      :custom-class='"dialog-setStyle"'>
      <div class='tipWrap'>
        <div class='tipTitle'>
          <i class='el-icon-warning'></i>
          温馨提示
        </div>
        <div class='tipContent'>此操作将永久删除该用户信息, 是否继续？</div>
      </div>
      <div class='saveBtn viewBtn'>
        <el-button type="primary" v-if='showType=="user"' @click="sendDel1">删除</el-button>
        <el-button type="primary" v-else @click="sendDel2">删除</el-button>
        <el-button type="info" @click="delTip=false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../utils/requst.js'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  props:{
    width:Number,
    height:Number
  },
  components: { Treeselect },
  data(){
    return{
      selectUser:{
        pageNum:1,
        pageSize:15,
        orderByColumn:'userId',
        userName:'',
        phonenumber:''
      },
      showType:null,
      userId:'',
      userList:[],
      deptList:[],
      roles:[],
      sexList:[],
      posts:[],
      title:"",
      userInfo:{},
      departmentInfo:{},
      total:0,
      isAdd:false,
      userShow:false,
      initPassword:null,
      userInShow:false,
      delTip:false,
      defaultProps: {
        children: "children",
        label: "label"
      },
      uploadInfo:{
        headers:{Authorization:"Bearer "+localStorage.getItem('token')},
        isUploading: false,
        updateSupport: false,
        url:`${process.env.VUE_APP_BASE_API}/system/user/importData`
      },
      userRules: {
        userName: [
          { required: true, message: "用户账号不能为空", trigger: "blur" },
          { min: 2, max: 20, message: '用户账号长度必须介于 2 和 20 之间', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: "用户名称不能为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "用户密码不能为空", trigger: "blur" },
          { min: 5, max: 20, message: '用户密码长度必须介于 5 和 20 之间', trigger: 'blur' }
        ],
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
        phonenumber: [
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ]
      },
      departmentShow:false,
      delTip:false,
      departmentRole:{
        parentId: [
          { required: true, message: "上级库区不能为空", trigger: "blur" }
        ],
        deptName: [
          { required: true, message: "库区名称不能为空", trigger: "blur" }
        ],
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
        phone: [
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ]
      }
    }
  },
  created(){
    this.getUser();
    this.getDeptList();
    this.getSexList();
    this.getRole();
    axios.get(`/system/config/configKey/sys.user.initPassword`).then(res=>{
      if(res){
        this.initPassword=res.msg
      }
    })
  },
  methods:{
    getDeptList(){
      axios.get('system/dept/treeselect').then(res=>{
        if(res){
          this.deptList=res.data;
        }
      })
    },
    getSexList(){
      axios.get('/system/dict/data/list',{params:{dictType:'sys_user_sex'}}).then(res=>{
        if(res){
          this.sexList=res.rows;
        }
      })
    },
    getRole(){
      let userId=''
      axios.get(`/system/user/${userId}`).then(res=>{
        if(res){
          this.roles=res.roles;
          this.posts=res.posts;
        }
      })
    },
    getUser(){
      axios.get('/system/user/list',{params:this.selectUser}).then(res=>{
        if(res){
          this.userList=res.rows;
          this.total=res.total;
        }
      })
    },
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.deptId,
        label: node.deptName,
        children: node.children
      };
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    addUser(){
      this.userShow=true;
      this.title='添加用户信息';
      this.userInfo.password=this.initPassword,
      this.userInfo.status='0';
      this.isAdd=true;
    },
    editUser(userId){
      axios.get(`/system/user/${userId}`).then(res=>{
        if(res){
          this.isAdd=false;
          this.userShow=true;
          this.title='修改用户信息';
          this.userInfo=res.data;
          this.userInfo.postIds = res.postIds;
          this.userInfo.roleIds = res.roleIds;
        }
      })
    },
    delUser(userId){
      this.delTip=true;
      this.userId=userId;
      this.showType='user'
    },
    sendDel1(){
      axios.delete(`/system/user/${this.userId}`).then(data=>{
        if(data){
          this.getUser()
          this.delTip=false;
          this.$message({
            type: 'success',
            message: '删除成功!',
            duration:1000
          });
        }
      })
    },
    resetPassword(row){
      this.$prompt(`请输入${row.userName}的新密码`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        cancelButtonClass:'cancel',
        inputPattern: /^.{5,20}$/,
        customClass:'messageInfo',
        inputErrorMessage: "用户密码长度必须介于 5 和 20 之间"
      }).then(({ value }) => {
          axios.put('/system/user/resetPwd',{
            userId:row.userId,
            password:value
          }).then(res=>{
            if(res){
              this.$message({
                type: 'success',
                message: '修改成功!',
                duration:1000
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消重置密码'
          }); 
        });
    },
    exportUserIn(){
      this.userInShow=true;
    },
    saveUser(obj){
      this.$refs[obj].validate((valid)=>{
        if(valid){
          if(this.isAdd){
            axios.post('/system/user',this.userInfo).then(data=>{
              if(data){
                this.getUser()
                this.$message({
                  type: 'success',
                  message: '添加成功!',
                  duration:1000
                });
                this.userShow=false;
              }
            })
          }else{
            axios.put('/system/user',this.userInfo).then(data=>{
              if(data){
                this.getUser()
                this.$message({
                  type: 'success',
                  message: '修改成功!',
                  duration:1000
                });
                this.userShow=false;
              }
            })
          }
        }else{
          return false
        }
      })
    },
    indexMethod(index){
      return (this.selectUser.pageNum-1)*this.selectUser.pageSize+index+1
    },
    pageChange(val){
      this.selectUser.pageNum=val;
      this.getUser()
    },
    handleSizeChange(val){
      this.selectUser.pageSize=val;
      this.getUser()
    },
    handleNodeClick(res){
      this.selectUser.deptId=res.id;
      this.departmentInfo.parentId=res.id;
      this.userInfo.deptId=res.id;
      this.getUser();
    },
    addDept(){
      this.departmentShow=true;
      if(this.departmentInfo.parentId=='100'){
        this.departmentInfo.parentId=null
      }
      this.isAdd=true;
      this.title='添加库区'
    },
    setOption(e,data,node){
      this.$contextmenu({
        items:[{
          label:'修改',
          icon:'icon el-icon-check',
          onClick:()=>{
            axios.get(`/system/dept/${data.id}`).then(res=>{
              if(res){
                this.isAdd=false;
                this.departmentShow=true;
                this.title='修改库区信息';
                this.departmentInfo=res.data;
              }
            })
          }
        },{
          label:'删除',
          icon:'icon el-icon-close',
          onClick:()=>{
            this.deptId=data.id;
            this.delTip=true;
            this.showType='dept'
          }
        }],
        event, 
        zIndex:999,
        minWidth:150
      });
      return false;
    },
    sendDel2(){
      axios.delete(`/system/dept/${this.deptId}`).then(data=>{
        if(data){
          this.getDeptList();
          this.delTip=false;
          this.$message({
            type: 'success',
            message: '删除成功!',
            duration:1000
          });
        }
      })
    },
    saveDepartment(obj){
      this.$refs[obj].validate((valid)=>{
        if(valid){
          if(this.isAdd){
            axios.post('/system/dept',this.departmentInfo).then(data=>{
              if(data){
                this.getDeptList()
                this.$message({
                  type: 'success',
                  message: '添加成功!',
                  duration:1000
                });
                this.departmentShow=false;
              }
            })
          }else{
            axios.put('/system/dept',this.departmentInfo).then(data=>{
              if(data){
                this.getDeptList()
                this.$message({
                  type: 'success',
                  message: '修改成功!',
                  duration:1000
                });
                this.departmentShow=false;
              }
            })
          }
        }else{
          return false
        }
      })
    },
    addAgent(){
      this.departmentShow=true;
      this.isAdd=true;
      this.title='添加代理商'
      this.departmentInfo={parentId:'100'}
    }
  }
}
</script>

<style lang="scss" scoped>
.user{
  .userMain{
    display: flex;
    .nav{
      border: 1px solid rgba(220, 229, 235, 0.6);
      width: 250px;
      margin-right: 10px;
      overflow-y: auto;
      flex-shrink: 0;
      box-sizing: border-box
    }
  }
}
</style>

