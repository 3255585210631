<template>
  <div class='wrap between' :style='`height:${height+30}px`'>
    <div class='head'>
      <el-form :inline="true" class="demo-form-inline" :model="selectMenu">
        <el-form-item label="菜单名称">
          <el-input v-model="selectMenu.menuName" clearable></el-input>
        </el-form-item>
        <el-form-item label="菜单状态">
          <el-select v-model="selectMenu.status" clearable placeholder="菜单状态">
            <el-option :label="item.statusName" :value="item.status" v-for='item in statusList' :key='item.status'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click='getMenu' icon="el-icon-search">确定</el-button>
        </el-form-item>
      </el-form>
      <div class='btnWrap'>
        <div class='btn' @click="addMenu">
          <svg-icon icon-class="add" class='icon'/>
          添加菜单
        </div>
        <div class='btn' @click="showFold">
          <div v-if='isExpandAll'>
            <svg-icon icon-class="fold" class='icon'/>
            折叠
          </div>
          <div v-else>
            <svg-icon icon-class="show" class='icon'/>
            展开
          </div>
        </div>
      </div>
    </div>
    <div class='primarys' :style='`height:${height-40}px`'>
      <el-table
        :data="menuList"
        v-if="refreshTable"
        v-loading="loading"
        row-key="menuId"
        :style="`min-width:967px;width:${width-42}px`"
        :header-cell-style='{"background":"#F5F9FA"}'
        :max-height="height-40"
        :default-expand-all="isExpandAll"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column prop="menuName" label="菜单名称" min-width='100' :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="orderNum" label="排序" align="center" min-width='50'></el-table-column>
        <el-table-column prop="perms" :show-overflow-tooltip="true" label="权限标识" align="center" min-width='100'></el-table-column>
        <el-table-column prop="component" :show-overflow-tooltip="true" label="组件路径" align="center" min-width='100'></el-table-column>
        <el-table-column prop="status" label="使用状态" align="center" min-width='100'>
          <template slot-scope="scope">
            <span :class='scope.row.status=="0"?"blue":"offLine"'>{{scope.row.status=='0'?"正常":"停用"}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" min-width='150'></el-table-column>
        <el-table-column 
          label="操作" 
          min-width="150" 
          fixed='right'
          align='center'>
          <template slot-scope="scope">
            <el-button @click.native.prevent="editMenu(scope.row.menuId)" type="text" size="small">修改菜单</el-button>
            <el-button @click.native.prevent="delMenu(scope.row.menuId)" type="text" size="small">删除菜单</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="title" :visible.sync="menuShow"
      :close-on-click-modal='false'
      width='780px'
      :custom-class='"dialog-setStyle"'>
      <div class='formWrap'>
        <el-form ref="menuInfo" :model="menuInfo" label-width="80px" :rules="menuRules">
          <div class='dialog-setWrap'>
            <el-form-item label="上级菜单" prop="parentId">
              <treeselect 
              v-model="menuInfo.parentId" 
              :options="menuOptions" 
              :normalizer="normalizer" 
              :show-count="true"
              placeholder="选择上级菜单" />
            </el-form-item>
            <el-form-item label="菜单类型" style='width:360px' class='radioWrap'>
              <el-radio-group v-model="menuInfo.menuType">
                <el-radio label="M">目录</el-radio>
                <el-radio label="C">菜单</el-radio>
                <el-radio label="F">按钮</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="菜单名称" prop='menuName'>
              <el-input v-model="menuInfo.menuName" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="菜单图标" v-if='menuInfo.menuType!="F"' prop='icon'>
              <el-input v-model="menuInfo.icon" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="菜单排序" prop='orderNum'>
              <el-input-number v-model="menuInfo.orderNum" controls-position="right" :min="0" :max="20"></el-input-number>
            </el-form-item>
            <el-form-item label="路由地址" v-if='menuInfo.menuType!="F"' prop='path'>
              <el-input v-model="menuInfo.path" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="组件路径" v-if='menuInfo.menuType=="C"'>
              <el-input v-model="menuInfo.component" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="权限字符" v-if='menuInfo.menuType!=="M"'>
              <el-input v-model="menuInfo.perms" @input='$forceUpdate()'></el-input>
            </el-form-item>
            
            <el-form-item label="路由参数" v-if='menuInfo.menuType=="C"'>
              <el-input v-model="menuInfo.query" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="是否外链"  v-if='menuInfo.menuType!="F"' style='width:360px' class='radioWrap'>
              <el-radio-group v-model="menuInfo.isFrame">
                <el-radio label="0">是</el-radio>
                <el-radio label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否缓存" v-if='menuInfo.menuType=="C"' style='width:360px' class='radioWrap'>
              <el-radio-group v-model="menuInfo.isCache">
                <el-radio label="0">缓存</el-radio>
                <el-radio label="1">不缓存</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="显示状态" v-if='menuInfo.menuType!="F"' style='width:360px' class='radioWrap'>
              <el-radio-group v-model="menuInfo.visible">
                <el-radio label="0">显示</el-radio>
                <el-radio label="1">隐藏</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="菜单状态" v-if='menuInfo.menuType!="F"' style='width:360px' class='radioWrap'>
              <el-radio-group v-model="menuInfo.status">
                <el-radio label="0">正常</el-radio>
                <el-radio label="1">停用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="menuInfo.remark" @input='$forceUpdate()'></el-input>
            </el-form-item>
          </div>
          <el-form-item class='saveBtn'>
            <el-button type="primary" @click="saveMenu('menuInfo')">保存</el-button>
            <el-button type="info" @click="menuShow=false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog 
      :visible.sync="delTip"
      :close-on-click-modal='false'
      width='400px'
      top='300px'
      :custom-class='"dialog-setStyle"'>
      <div class='tipWrap'>
        <div class='tipTitle'>
          <i class='el-icon-warning'></i>
          温馨提示
        </div>
        <div class='tipContent'>此操作将永久删除该菜单信息，请谨慎操作，是否继续？</div>
      </div>
      <div class='saveBtn viewBtn'>
        <el-button type="primary" @click="sendDel">删除</el-button>
        <el-button type="info" @click="delTip=false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../utils/requst.js'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  props:{
    width:Number,
    height:Number
  },
  components: { Treeselect },
  data(){
    return{
      selectMenu:{
        pageNum:1,
        pageSize:1000,
        isAsc:'desc',
        orderByColumn:'menuId',
        menuName:'',
        status:''
      },
      menuId:null,
      menuList:[],
      menuOptions:[],
      menuInfo:{},
      statusList:[{
        statusName:'正常',
        status:0
      },{
        statusName:'停用',
        status:1
      }],
      title:'',
      menuShow:false,
      refreshTable:true,
      isExpandAll:true,
      loading:false,
      isAdd:false,
      delTip:false,
      menuRules:{
        menuName: [
          { required: true, message: "菜单名称不能为空", trigger: "blur" }
        ],
        orderNum: [
          { required: true, message: "菜单顺序不能为空", trigger: "blur" }
        ],
        path: [
          { required: true, message: "路由地址不能为空", trigger: "blur" }
        ],
        icon: [
          { required: true, message: "菜单图标不能为空", trigger: "blur" }
        ],
        component:[
          { required: true, message: "组件路径不能为空", trigger: "blur" }
        ]
      }
    }
  },
  created(){
    this.getMenu()
  },
  methods:{
    getMenu(){
      this.loading=true;
      axios.get('/system/menu/list',{params:this.selectMenu}).then(res=>{
        this.loading=false
        if(res){
          let menuList=this.handleTree(res.data,'menuId');
          this.menuList=menuList;
          this.menuOptions=[];
          const menu = { menuId: 0, menuName: '主类目', children: [] };
          menu.children=menuList;
          this.menuOptions.push(menu);
        }
      })
    },
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.menuId,
        label: node.menuName,
        children: node.children
      };
    },
    addMenu(){
      this.title='添加菜单信息';
      this.menuShow=true;
      this.isAdd=true;
      this.menuInfo={
        menuType:'M',
        visible:'0',
        status:'0',
        isCache:'0',
        isFrame:'1',
        parentId: 0
      }
    },
    editMenu(menuId){
      axios.get(`/system/menu/${menuId}`).then(res=>{
        if(res){
          this.title='修改菜单信息';
          this.menuShow=true;
          this.isAdd=false;
          this.menuInfo=res.data;
        }
      })
    },
    delMenu(menuId){
      this.menuId=menuId;
      this.delTip=true;
    },
    sendDel(){
      axios.delete(`/system/menu/${this.menuId}`).then(data=>{
        if(data){
          this.getMenu();
          this.delTip=false;
          this.$message({
            type: 'success',
            message: '删除成功!',
            duration:1000
          });
        }
      })
    },
    saveMenu(obj){
      this.$refs[obj].validate((valid)=>{
        if(valid){
          if(this.isAdd){
            axios.post('/system/menu',this.menuInfo).then(data=>{
              if(data){
                this.getMenu()
                this.$message({
                  type: 'success',
                  message: '添加成功!',
                  duration:1000
                });
                this.menuShow=false;
              }
            })
          }else{
            axios.put('/system/menu',this.menuInfo).then(data=>{
              if(data){
                this.getMenu()
                this.$message({
                  type: 'success',
                  message: '修改成功!',
                  duration:1000
                });
                this.menuShow=false;
              }
            })
          }
        }else{
          return false
        }
      })
    },
    showFold(){
      this.refreshTable = false;
      this.isExpandAll = !this.isExpandAll;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    }
  }
}
</script>

<style>

</style>
