import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import vueAxios from 'vue-axios'
import elementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { handleTree } from "@/utils/handelTree";
import { getDicts } from "@/utils/dict/data";
import DictTag from "@/components/DictTag"
import DictData from '@/components/DictData'
import {selectDictLabel, selectDictLabels} from "@/utils/ruoyi";
import {parseTime} from "@/utils/libMethods"
import '@/assets/style/lib.scss'
import './assets/icons'
import ContextMenu from 'vue-contextmenujs'
Vue.config.productionTip = false
Vue.prototype.handleTree = handleTree
Vue.prototype.parseTime = parseTime
Vue.prototype.getDicts = getDicts
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.use(vueAxios,axios)
Vue.use(elementUI,{size:'small',zIndex:3000})
Vue.use(ContextMenu)
Vue.component('DictTag', DictTag)
DictData.install()
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
