<template>
  <div class='wrap' :style='`height:${height-12}px`'>
    <div class='head'>
      <el-form :inline="true" class="demo-form-inline" :model="selectUserOnline">
        <el-form-item label="用户账号">
          <el-input v-model="selectUserOnline.userName" clearable @keyup.enter.native="getOnLineList"></el-input>
        </el-form-item>
        <el-form-item label="登录地址">
          <el-input v-model="selectUserOnline.ipaddr" clearable @keyup.enter.native="getOnLineList"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click='getOnLineList' icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class='primarys' :style='`height:${height-82}px`'>
      <el-table
        :data="onLineList"
        :style="`min-width:967px;width:${width-40}px;margin:-1px`"
        :header-cell-style='{"background":"#F5F9FA"}'
        :max-height="height-80"
        border>
        <el-table-column type="index" width="60" align='center' label="序号" :index="indexMethod"></el-table-column>
        <el-table-column label="会话编号" align="center" prop="tokenId" :show-overflow-tooltip="true" min-width='100'/>
        <el-table-column label="用户账号" align="center" prop="userName" :show-overflow-tooltip="true" min-width='100'/>
        <el-table-column label="所属部门" align="center" prop="deptName" min-width='100'/>
        <el-table-column label="主机" align="center" prop="ipaddr" :show-overflow-tooltip="true" min-width='100'/>
        <el-table-column label="登录地点" align="center" prop="loginLocation" :show-overflow-tooltip="true" min-width='100'/>
        <el-table-column label="浏览器" align="center" prop="browser" min-width='100'/>
        <el-table-column label="操作系统" align="center" prop="os" min-width='100'/>
        <el-table-column prop="loginTime" label="登录时间" align="center" min-width='120' :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{parseTime(scope.row.loginTime)}}</span>
          </template>
        </el-table-column>
        <el-table-column 
          label="操作" 
          min-width="120" 
          fixed='right'
          align='center'>
          <template slot-scope="scope">
            <el-button @click.native.prevent="forceLogout(scope.row)" type="text" size="small">强制退出</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        class='pagination'
        @size-change="handleSizeChange"
        :page-sizes="[15, 30, 45, 60]"
        :total="total"
        :page-size='selectUserOnline.pageSize'
        :current-page='selectUserOnline.pageNum'
        @current-change='pageChange'>
      </el-pagination>
    </div>
    <el-dialog 
      :visible.sync="delTip"
      :close-on-click-modal='false'
      width='400px'
      top='300px'
      :custom-class='"dialog-setStyle"'>
      <div class='tipWrap'>
        <div class='tipTitle'>
          <i class='el-icon-warning'></i>
          温馨提示
        </div>
        <div class='tipContent'>是否确认强退名称为{{userName}}的用户？</div>
      </div>
      <div class='saveBtn viewBtn'>
        <el-button type="primary" @click="sendDel">退出</el-button>
        <el-button type="info" @click="delTip=false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../utils/requst.js'
export default {
  props:{
    width:Number,
    height:Number
  },
  data(){
    return{
      selectUserOnline:{
        pageNum:1,
        pageSize:15,
        userName:'',
        ipaddr:""
      },
      onLineList:[],
      total:0,
      delTip:false,
      userName:''
    }
  },
  created(){
    this.getOnLineList()
  },
  methods:{
    getOnLineList(){
      axios.get('/monitor/online/list',{params:this.selectUserOnline}).then(res=>{
        if(res){
          this.onLineList=res.rows;
          this.total=res.total;
        }
      })
    },
    forceLogout(row){
      this.delTip=true;
      this.userName=row.userName;
    },
    sendDel(){
      axios.delete(`/monitor/online/${row.tokenId}`).then(data=>{
        if(data){
          this.getOnLineList();
          this.delTip=false;
          this.$message({
            type: 'success',
            message: '强退成功!',
            duration:1000
          });
        }
      })
    },
    indexMethod(index){
      return (this.selectUserOnline.pageNum-1)*this.selectUserOnline.pageSize+index+1
    },
    pageChange(val){
      this.selectUserOnline.pageNum=val;
      this.getOnLineList()
    },
    handleSizeChange(val){
      this.selectUserOnline.pageSize=val;
      this.getOnLineList()
    }
  }
}
</script>

<style>

</style>
