<template>
  <div class='wrap' :style='`height:${height-20}px`'>
    <div class='head'>
      <el-form :inline="true" class="demo-form-inline" :model="selectDepartment">
        <el-form-item label="库区名称">
          <el-input v-model="selectDepartment.deptName" clearable></el-input>
        </el-form-item>
        <el-form-item label="库区状态">
          <el-select v-model="selectDepartment.status" clearable placeholder="库区状态">
            <el-option :label="item.statusName" :value="item.status" v-for='item in statusList' :key='item.status'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click='getDepartment' icon="el-icon-search">确定</el-button>
        </el-form-item>
      </el-form>
      <div class='btnWrap'>
        <div class='btn' @click="addDepartment">
          <svg-icon icon-class="add" class='icon'/>
          添加库区
        </div>
        <div class='btn' @click="showFold">
          <div v-if='isExpandAll'>
            <svg-icon icon-class="fold" class='icon'/>
            折叠
          </div>
          <div v-else>
            <svg-icon icon-class="show" class='icon'/>
            展开
          </div>
        </div>
      </div>
    </div>
    <div class='primarys' :style='`height:${height-100}px`'>
      <el-table
        :data="departmentList"
        v-if="refreshTable"
        v-loading="loading"
        row-key="deptId"
        :style="`min-width:967px;width:${width-42}px;`"
        :header-cell-style='{"background":"#F5F9FA"}'
        :max-height="height-97"
        :default-expand-all="isExpandAll"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column prop="deptName" label="库区名称" :show-overflow-tooltip="true" min-width='150'></el-table-column>
        <el-table-column prop="orderNum" label="排序"  min-width='50'></el-table-column>
        <el-table-column prop="leader" label="负责人"  min-width='100'></el-table-column>
        <el-table-column prop="phone" label="电话"  min-width='100'></el-table-column>
        <el-table-column prop="email" label="邮箱" min-width='100'></el-table-column>
        <el-table-column prop="status" label="使用状态" min-width='80'>
          <template slot-scope="scope">
            <span :class='scope.row.status=="0"?"blue":"offLine"'>{{scope.row.status=='0'?"正常":"停用"}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width='150'></el-table-column>
        <el-table-column 
          label="操作" 
          min-width="150" 
          fixed='right'
          align='center'>
          <template slot-scope="scope">
            <el-button @click.native.prevent="editDepartment(scope.row.deptId)" type="text" size="small">修改库区</el-button>
            <el-button v-if="scope.row.parentId != 0" @click.native.prevent="delDepartment(scope.row.deptId)" type="text" size="small">删除库区</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="title" :visible.sync="departmentShow"
      :close-on-click-modal='false'
      width='860px'
      :custom-class='"dialog-setStyle"'>
      <div class='formWrap'>
        <el-form ref="departmentInfo" :model="departmentInfo" label-width="120px" :rules="departmentRole">
          <div class='dialog-setWrap'>
            <el-form-item label="上级公司/库区" prop="parentId" v-if="departmentInfo.parentId!= 0" >
              <treeselect v-model="departmentInfo.parentId" :options="departmentList2" :normalizer="normalizer" placeholder="选择上级公司/库区"/>
            </el-form-item>
            <el-form-item label="库区名称" prop='deptName'>
              <el-input v-model="departmentInfo.deptName" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <!-- <el-form-item label="库区排序" prop='orderNum'>
              <el-input-number v-model="departmentInfo.orderNum" controls-position="right" :min="departmentInfo.parentId!= 0?1:0" :max="20"></el-input-number>
            </el-form-item> -->
            <el-form-item label="负责人" prop='leader'>
              <el-input v-model="departmentInfo.leader" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop='phone'>
              <el-input v-model="departmentInfo.phone" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop='email'>
              <el-input v-model="departmentInfo.email" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="库区状态"  class='radioWrap' style='width:360px'>
              <el-radio-group v-model="departmentInfo.status">
                <el-radio label="0">正常</el-radio>
                <el-radio label="1">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <el-form-item class='saveBtn'>
            <el-button type="primary" @click="saveDepartment('departmentInfo')">保存</el-button>
            <el-button type="info" @click="departmentShow=false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog 
      :visible.sync="delTip"
      :close-on-click-modal='false'
      width='400px'
      top='300px'
      :custom-class='"dialog-setStyle"'>
      <div class='tipWrap'>
        <div class='tipTitle'>
          <i class='el-icon-warning'></i>
          温馨提示
        </div>
        <div class='tipContent'>此操作将永久删除该库区信息，请谨慎操作，是否继续？</div>
      </div>
      <div class='saveBtn viewBtn'>
        <el-button type="primary" @click="sendDel">删除</el-button>
        <el-button type="info" @click="delTip=false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../utils/requst.js'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  props:{
    width:Number,
    height:Number
  },
  components: { Treeselect },
  data(){
    return{
      selectDepartment:{
        isAsc:'desc',
        orderByColumn:'dictId',
        dictName:'',
        dictType:''
      },
      isExpandAll:true,
      refreshTable:true,
      loading:false,
      deptId:null,
      departmentList:[],
      departmentList2:[],
      title:"",
      statusList:[{
        statusName:'正常',
        status:0
      },{
        statusName:'停用',
        status:1
      }],
      departmentInfo:{
        status:'0'
      },
      isAdd:false,
      departmentShow:false,
      delTip:false,
      departmentRole:{
        parentId: [
          { required: true, message: "上级库区不能为空", trigger: "blur" }
        ],
        deptName: [
          { required: true, message: "库区名称不能为空", trigger: "blur" }
        ],
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
        phone: [
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ]
      }
    }
  },
  created(){
    this.getDepartment()
  },
  methods:{
    getDepartment(){
      this.loading=true;
      axios.get('/system/dept/list',{params:this.selectDepartment}).then(res=>{
        this.loading=false
        if(res){
          this.departmentList=this.handleTree(res.data, "deptId");
        }
      })
    },
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.deptId,
        label: node.deptName,
        children: node.children
      };
    },
    addDepartment(){
      this.isAdd=true;
      this.departmentShow=true;
      this.title='添加库区信息'
    },
    editDepartment(deptId){
      axios.get('/system/dept/list').then(res=>{
        if(res){
          this.departmentList2=this.handleTree(res.data, "deptId");
          axios.get(`/system/dept/${deptId}`).then(res=>{
            if(res){
              this.isAdd=false;
              this.departmentShow=true;
              this.title='修改库区信息';
              this.departmentInfo=res.data;
            }
          })
        }
      })
     
    },
    delDepartment(deptId){
      this.deptId=deptId;
      this.delTip=true;
    },
    sendDel(){
      axios.delete(`/system/dept/${this.deptId}`).then(data=>{
        if(data){
          this.getDepartment();
          this.delTip=false;
          this.$message({
            type: 'success',
            message: '删除成功!',
            duration:1000
          });
        }
      })
    },
    showFold(){
      this.refreshTable = false;
      this.isExpandAll = !this.isExpandAll;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    },
    saveDepartment(obj){
      this.$refs[obj].validate((valid)=>{
        if(valid){
          if(this.isAdd){
            axios.post('/system/dept',this.departmentInfo).then(data=>{
              if(data){
                this.getDepartment()
                this.$message({
                  type: 'success',
                  message: '添加成功!',
                  duration:1000
                });
                this.departmentShow=false;
              }
            })
          }else{
            axios.put('/system/dept',this.departmentInfo).then(data=>{
              if(data){
                this.getDepartment()
                this.$message({
                  type: 'success',
                  message: '修改成功!',
                  duration:1000
                });
                this.departmentShow=false;
              }
            })
          }
        }else{
          return false
        }
      })
    }
  }
}
</script>

<style>

</style>
