<template>
  <div class='content'>
    <div class='nav'>
      <div class='title'>
        <svg-icon icon-class="homePage" class='titleIcon'/>
        系统管理中心
      </div>
      <div 
        v-for='(item,index) in navList'
        :key='index'
        v-if='!item.hidden'
        :class='["nav-item",{active:active==item.path}]' 
        @click='changePage(item.path)'>
        <div>
          <svg-icon :icon-class="item.meta.icon" class='menuIcon'/>
          {{item.meta.title}}
        </div>   
      </div>
    </div>
    <div class='containner' :style='`width:${width}px;height:${height+50}px`'>
      <component 
        :height='height'
        :width='width'
        :param='param'
        :argument='argument'
        v-on="$listeners"
        @emitInfo='toPage'
        :is='active'>
      </component>
    </div>
  </div>
</template>

<script>
import user from '../components/User'
import role from '../components/Role'
import menuManage from '../components/menu'
import department from '../components/Department'
import dictionary from '../components/Dictionary'
import configPage from '../components/ConfigPage'
import parameter from '../components/Parameter'
import userOnline from '../components/UserOnLine'
import taskSetTime from '../components/TaskSetTime'
import taskLog from '../components/TaskLog'
import ioTCard from '../components/IoTCard'
import agent from '../components/Agent'
import axios from '../utils/requst.js'
export default {
  components:{
    user,
    role,
    menuManage,
    department,
    dictionary,
    configPage,
    parameter,
    userOnline,
    taskSetTime,
    taskLog,
    ioTCard,
    agent
  },
  data(){
    return{
      width:0,
      height:0,
      active:'user',
      param:{},
      argument:null,
      navList:[]
    }
  },
  created(){
   this.getSize();
   this.getRoter();
  },
  mounted(){
    window.onresize=()=>{
      this.getSize();
    }
  },
  methods:{
    getSize(){
      this.height=document.documentElement.clientHeight-50;
      this.width=document.documentElement.clientWidth-218;
    },
    changePage(page){
      this.active=page;
      this.param={}
    },
    toPage(res){
      this.active=res.page;
      this.param=res.param;
      this.argument=res.argument;
    },
    getRoter(){
      axios.get('/getRouters').then(res=>{
        if(res){
          let data=res.data;
          let arr1=data.filter(event=>event.name=='System')[0].children.filter(item=>item.name!='Notice'&&item.name!='Post'&&item.name!='Log');
          let arr2=data.filter(item=>item.name=='Monitor')[0].children.filter(item=>item.name=='UserOnline'||item.name=='TaskSetTime');
          let arr3=data.filter(item=>item.name=='Sim'||item.name=='Agent');
          let nav=[...arr1,...arr2,...arr3[0].children,...arr3[1].children]
          this.navList=nav;
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  height: 100%;
  width: 100%;
  display: flex;
  .nav{
    width: 218px;
    height: 100%;
    background-image: url('../assets/bg/menuBg.png');
    color: #FFFFFF;
    font-size: 16px;
    padding: 10px;
    padding-top: 0px;
    box-sizing: border-box;
    flex-shrink: 0;
    position: relative;
    .title{
      height: 71px;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      padding-left: 10px;
      box-sizing: border-box;
      .titleIcon{
        font-size: 36px;
        margin-right: 20px;
      }
    }
    .nav-item{
      width: 200px;
      height: 40px;
      color: rgba(255,255,255,.6);
      padding-left: 15px;
      box-sizing: border-box;
      line-height: 40px;
      margin-bottom: 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .menuIcon{
        margin-right: 10px;
        opacity: .6;
      }
    }    
    .active{
      color:rgb(255,255,255);
      background: linear-gradient( 270deg, rgba(53,175,255,0) 0%, #0054FB 100%);
      border-radius: 20px 20px 20px 20px;
      .menuIcon{
        opacity: 1;
      }
    }
  }
  .containner{
    background: #F4F9FD;
    padding: 10px;
    box-sizing: border-box;
    min-width: 1024px;
    position: relative;
  }
}
</style>

