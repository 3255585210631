<template>
  <div class='wrap' :style='`height:${height-12}px`'>
    <div class='head'>
      <el-form :inline="true" class="demo-form-inline" :model="selectTaskLog">
        <el-form-item label="任务名称">
          <el-input v-model="selectTaskLog.jobName" clearable></el-input>
        </el-form-item>
        <el-form-item label="任务组名">
          <el-select v-model="selectTaskLog.jobGroup" clearable placeholder="请选择" @change='$forceUpdate()'>
            <el-option
              v-for="item in dict.type.sys_job_group"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="执行时间">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            style="width: 240px"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click='getTaskLogList' icon="el-icon-search">确定</el-button>
        </el-form-item>
      </el-form>
      <div class='btnWrap'>
        <div class='btn' @click="clearLog">
          <svg-icon icon-class="clear" class='icon'/>
          清空
        </div>
        <div class='btn' @click="goBack">
          <svg-icon icon-class="back" class='icon'/>
          返回
        </div>
      </div>
    </div>
    <div class='primarys' :style='`height:${height-82}px`'>
      <el-table
        :data="taskLogList"
        :style="`min-width:967px;width:${width-40}px;margin:-1px`"
        :header-cell-style='{"background":"#F5F9FA"}'
        :max-height="height-80"
        border>
        <el-table-column type="index" width="70" align='center' label="序号" :index="indexMethod"></el-table-column>
        <el-table-column prop="jobName" :show-overflow-tooltip="true" label="任务名称" align="center" min-width='100'></el-table-column>
        <el-table-column prop="jobGroup" :show-overflow-tooltip="true" label="任务分组" align="center" min-width='100'>
          <template slot-scope="scope">
            <dict-tag :options="dict.type.sys_job_group" :value="scope.row.jobGroup"/>
          </template>
        </el-table-column>
        <el-table-column label="调用方法" align="center" prop="invokeTarget" :show-overflow-tooltip="true" min-width='120'></el-table-column>
        <el-table-column label="日志信息" align="center" prop="jobMessage" :show-overflow-tooltip="true" min-width='130'></el-table-column>
        <el-table-column prop="status" label="执行状态" align="center" min-width='100' sortable>
          <template slot-scope="scope">
            <span :class='scope.row.status=="0"?"blue":"offLine"'>{{scope.row.status=='0'?"成功":"失败"}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="执行时间" align="center" min-width='130'></el-table-column>
        <el-table-column 
          label="操作" 
          min-width="130" 
          fixed='right'
          align='center'>
          <template slot-scope="scope">
            <el-button @click.native.prevent="viewLog(scope.row)" type="text" size="small">日志详情</el-button>
            <el-button @click.native.prevent="delLog(scope.row.jobLogId)" type="text" size="small">删除日志</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size='selectTaskLog.pageSize'
        :page-sizes="[15, 30, 45, 60]"
        :current-page='selectTaskLog.pageNum'
        @size-change="handleSizeChange"
        @current-change='pageChange'
        class='pagination'>
      </el-pagination>
    </div>
    <el-dialog title="任务详细信息" :visible.sync="logShow"
      :close-on-click-modal='false'
      width='740px'
      :custom-class='"dialog-setStyle"'>
      <div class='viewWrap'>
        <div class='dialog-descriptions'>
          <el-descriptions :column="2" border>
            <el-descriptions-item label="任务名称" label-class-name="myLabel" content-class-name="myContent">{{logInfo.jobName}}</el-descriptions-item>
            <el-descriptions-item label="任务分组" label-class-name="myLabel" content-class-name="myContent"><dict-tag :options="dict.type.sys_job_group" :value="logInfo.jobGroup"/></el-descriptions-item>
            <el-descriptions-item label="调用方法" :span="2" label-class-name="myLabel" content-class-name="myContent">{{logInfo.invokeTarget}}</el-descriptions-item>
            <el-descriptions-item label="日志信息" :span="2" label-class-name="myLabel" content-class-name="myContent">{{logInfo.jobMessage}}</el-descriptions-item>
            <el-descriptions-item label="执行状态" label-class-name="myLabel" content-class-name="myContent">{{logInfo.status=='0'?"成功":"失败"}}</el-descriptions-item>
            <el-descriptions-item label="执行时间" label-class-name="myLabel" content-class-name="myContent">{{logInfo.createTime}}</el-descriptions-item>
            <el-descriptions-item label="异常信息" label-class-name="myLabel" content-class-name="myContent">{{logInfo.exceptionInfo}}</el-descriptions-item>
          </el-descriptions>       
        </div>
        <div class='saveBtn viewBtn'>
          <el-button type="primary" @click="logShow=false">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog 
      :visible.sync="clearShow"
      :close-on-click-modal='false'
      width='400px'
      top='300px'
      :custom-class='"dialog-setStyle"'>
      <div class='tipWrap'>
        <div class='tipTitle'>
          <i class='el-icon-warning'></i>
          温馨提示
        </div>
        <div class='tipContent'>此操作将永久清空所有的日志信息,请谨慎操作, 是否继续？</div>
      </div>
      <div class='saveBtn viewBtn'>
        <el-button type="primary" @click="clearAll">清空</el-button>
        <el-button type="info" @click="clearShow=false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog 
      :visible.sync="delTip"
      :close-on-click-modal='false'
      width='400px'
      top='300px'
      :custom-class='"dialog-setStyle"'>
      <div class='tipWrap'>
        <div class='tipTitle'>
          <i class='el-icon-warning'></i>
          温馨提示
        </div>
        <div class='tipContent'>此操作将永久删除该日志信息，请谨慎操作，是否继续？</div>
      </div>
      <div class='saveBtn viewBtn'>
        <el-button type="primary" @click="sendDel">删除</el-button>
        <el-button type="info" @click="delTip=false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../utils/requst.js'
import {addDateRange} from '../utils/libMethods.js'
export default {
  props:{
    width:Number,
    height:Number,
    argument:String,
    param:Object
  },
  dicts:['sys_job_group'],
  data(){
    return{
      selectTaskLog:{
        pageNum: 1,
        pageSize: 15,
        orderByColumn: "jobLogId",
        isAsc: "desc",
        jobName: undefined,
        jobGroup:undefined
      },
      dateRange:[],
      taskLogList:[],
      total:0,
      logShow:false,
      logInfo:{},
      clearShow:false,
      delTip:false,
      jobLogId:null
    }
  },
  created(){
    this.getTaskLogList()
  },
  methods:{
    goBack() {
      this.$emit('emitInfo',{
        page:'taskSetTime',
        argument:this.argument,
        param:this.param
      })
    },
    getTaskLogList(){
      let param=addDateRange(this.selectTaskLog,this.dateRange)
      axios.get('/monitor/jobLog/list',{params:param}).then(res=>{
        if(res){
          this.taskLogList=res.rows;
          this.total=res.total
        }
      })
    },
    clearLog(){
      this.clearShow=true;
    },
    clearAll(){
      axios.delete(`/monitor/jobLog/clean`).then(data=>{
        if(data){
          this.getTaskLogList();
          this.clearShow=false;
          this.$message({
            type: 'success',
            message: '清空成功!',
            duration:1000
          });
        }
      })
    },
    viewLog(row){
      this.logInfo=row;
      this.logShow=true;
    },
    delLog(jobLogId){
      this.delTip=true;
      this.jobLogId=jobLogId
    },
    sendDel(){
      axios.delete(`/monitor/jobLog/${this.jobLogId}`).then(data=>{
        if(data){
          this.getTaskLogList();
          this.delTip=false;
          this.$message({
            type: 'success',
            message: '删除成功!',
            duration:1000
          });
        }
      })
    },
    indexMethod(index){
      return (this.selectTaskLog.pageNum-1)*this.selectTaskLog.pageSize+index+1
    },
    pageChange(val){
      this.selectTaskLog.pageNum=val;
      this.getTaskLogList()
    },
    handleSizeChange(val){
      this.selectTaskLog.pageSize=val;
      this.getTaskLogList()
    },
  }
}
</script>

<style>

</style>
