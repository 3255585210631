<template>
  <div class='wrap between' :style='`height:${height-12}px`'>
    <div class='head'>
      <el-form :inline="true" class="demo-form-inline" :model="selectParam">
        <el-form-item label="字典标签">
          <el-input v-model='selectParam.dictLabel'></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click='getDictList' icon="el-icon-search">确定</el-button>
        </el-form-item>
      </el-form>
      <div class='btnWrap'>
        <div class='btn' @click="addDict">
          <svg-icon icon-class="add" class='icon'/>
          添加字典
        </div>
        <div class='btn' @click="goBack">
          <svg-icon icon-class="back" class='icon'/>
          返回
        </div>
      </div>
    </div>
    <div class='primarys' :style='`height:${height-82}px`'>
      <el-table
        :data="dictList"
        :style="`min-width:967px;width:${width-40}px;margin:-1px`"
        :header-cell-style='{"background":"#F5F9FA"}'
        :max-height="height-80"
        border>
        <el-table-column type="index" width="60" align='center' label="序号" :index="indexMethod"></el-table-column>
        <el-table-column prop="dictLabel" label="字典标签" align="center" min-width='150' :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="dictValue" label="字典键值" align="center" min-width='100'></el-table-column>
        <el-table-column prop="dictSort" label="字典排序" align="center" min-width='100'></el-table-column>
        <el-table-column prop="status" label="使用状态" align="center" min-width='100' sortable>
          <template slot-scope="scope">
            <span :class='scope.row.status=="0"?"blue":"offLine"'>{{scope.row.status=='0'?"正常":"停用"}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center" min-width='150' :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" min-width='150'></el-table-column>
        <el-table-column 
          label="操作" 
          min-width="150" 
          fixed='right'
          align='center'>
          <template slot-scope="scope">
            <el-button @click.native.prevent="editDict(scope.row.dictCode)" type="text" size="small">修改</el-button>
            <el-button @click.native.prevent="delDict(scope.row.dictCode)" type="text" class='del' size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-sizes="[15, 30, 45, 60]"
        :page-size='selectParam.pageSize'
        :current-page='selectParam.pageNum'
        @current-change='pageChange'
        @size-change="handleSizeChange"
        class='pagination'>
      </el-pagination>
    </div>
    <el-dialog :title="title" :visible.sync="dictShow"
      :close-on-click-modal='false'
      width='780px'
      :custom-class='"dialog-setStyle"'>
      <div class='formWrap'>
        <el-form ref="dictInfo" :model="dictInfo" label-width="80px">
          <div class='dialog-setWrap'>
            <el-form-item label="数据标签">
              <el-input v-model="dictInfo.dictLabel" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="字典类型">
              <el-input v-model="dictInfo.dictType" @input='$forceUpdate()' disabled></el-input>
            </el-form-item>
            <el-form-item label="数据键值">
              <el-input v-model="dictInfo.dictValue" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="显示排序">
              <el-input-number v-model="dictInfo.dictSort" controls-position="right" :min="dictInfo.parentId!= 0?1:0" :max="20"></el-input-number>
            </el-form-item>
            <el-form-item label="样式属性">
              <el-input v-model="dictInfo.cssClass" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="使用状态" style='width:360px' class='radioWrap'>
              <el-radio-group v-model="dictInfo.status">
                <el-radio label="0">正常</el-radio>
                <el-radio label="1">停用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="备注">
              <el-input type="textarea"  v-model="dictInfo.remark" @input='$forceUpdate()'></el-input>
            </el-form-item>
          </div>
          <el-form-item class='saveBtn'>
            <el-button type="primary" @click="saveDict('dictInfo')">保存</el-button>
            <el-button type="info" @click="dictShow=false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog 
      :visible.sync="delTip"
      :close-on-click-modal='false'
      width='400px'
      top='300px'
      :custom-class='"dialog-setStyle"'>
      <div class='tipWrap'>
        <div class='tipTitle'>
          <i class='el-icon-warning'></i>
          温馨提示
        </div>
        <div class='tipContent'>此操作将永久删除该字典数据信息, 是否继续？</div>
      </div>
      <div class='saveBtn viewBtn'>
        <el-button type="primary" @click="sendDel">删除</el-button>
        <el-button type="info" @click="delTip=false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../utils/requst.js'
export default {
  props:{
    width:Number,
    height:Number,
    argument:String,
    param:Object
  },
  data(){
    return{
      selectParam:{
        dictLabel:'',
        dictType:this.argument,
        pageNum:1,
        pageSize:15
      },
      dictList:[],
      total:0,
      dictInfo:{status:'0'},
      dictShow:false,
      isAdd:false,
      title:'',
      delTip:false,
      dictCode:''
    }
  },
  created(){
    this.getDictList()
  },
  methods:{
    goBack() {
      this.$emit('emitInfo',{
        page:'dictionary',
        argument:this.argument,
        param:this.param
      })
    },
    getDictList(){
      axios.get('/system/dict/data/list',{params:this.selectParam}).then(res=>{
        if(res){
          this.total=res.total;
          this.dictList=res.rows;
        }
      })
    },
    addDict(){
      this.isAdd=true;
      this.dictShow=true;
      this.title='添加字典数据信息';
      this.dictInfo={
        status:'0',
        dictType:this.argument
      }
    },
    editDict(dictCode){
      axios.get(`/system/dict/data/${dictCode}`).then(res=>{
        if(res){
          this.isAdd=false;
          this.dictShow=true;
          this.title='修改字典数据信息';
          this.dictInfo=res.data;
        }
      })
    },
    delDict(dictCode){
      this.delTip=true;
      this.dictCode=dictCode;
    },
    sendDel(){
      axios.delete(`/system/dict/data/${this.dictCode}`).then(data=>{
        if(data){
          this.getDictList()
          this.delTip=false;
          this.$message({
            type: 'success',
            message: '删除成功!',
            duration:1000
          });
        }
      })
    },
    saveDict(obj){
      this.$refs[obj].validate((valid)=>{
        if(valid){
          if(this.isAdd){
            axios.post('/system/dict/data',this.dictInfo).then(data=>{
              if(data){
                this.getDictList()
                this.$message({
                  type: 'success',
                  message: '添加成功!',
                  duration:1000
                });
                this.dictShow=false;
              }
            })
          }else{
            axios.put('/system/dict/data',this.dictInfo).then(data=>{
              if(data){
                this.getDictList()
                this.$message({
                  type: 'success',
                  message: '修改成功!',
                  duration:1000
                });
                this.dictShow=false;
              }
            })
          }
        }else{
          return false
        }
      })
    },
    indexMethod(index){
      return (this.selectParam.pageNum-1)*this.selectParam.pageSize+index+1
    },
    pageChange(val){
      this.selectParam.pageNum=val;
      this.getDictList()
    },
    handleSizeChange(val){
      this.selectParam.pageSize=val;
      this.getDictList()
    }
  }
}
</script>

<style lang="scss" scoped>
.between{
  .dialog-setStyle{
    .dialog-setWrap{
      justify-content: space-between !important;
    }
  }
}
</style>


