<template>
  <div class='wrap ioTCard' :style='`height:${height-12}px`'>
    <div class='head'>
      <el-form :inline="true" class="demo-form-inline" :model="selectCard">
        <el-form-item label="sim卡号">
          <el-input v-model="selectCard.simId" clearable></el-input>
        </el-form-item>
        <el-form-item label="ICCID">
          <el-input v-model="selectCard.iccId" clearable></el-input>
        </el-form-item>
        <el-form-item label="所属库区">
          <el-input v-model="selectCard.deptName" clearable></el-input>
        </el-form-item>
        <el-form-item label="网卡状态">
          <el-select v-model="selectCard.state" placeholder="请选择网卡状态">
            <el-option
              v-for="item in dict.type.sim_state"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡有效期">
          <el-date-picker
            v-model="selectCard.expiredAt"
            type="date"
            align="right"
            value-format="yyyy-MM-dd"
            placeholder="选择卡有效期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click='getCardList' icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
      <div class='btnWrap'>
        <div class='btn' @click="exportCard">
          <svg-icon icon-class="derive" class='icon'/>
          导出
        </div>
      </div>
    </div>
    <div class='primarys' :style='`height:${height-82}px`'>
      <el-table
        :data="cardList"
        ref="user-table"
        :style="`min-width:967px;width:${width-40}px;margin:-1px`"
        :header-cell-style='{"background":"#F5F9FA"}'
        :max-height="height-80"
        border>
        <el-table-column type="index" width="60" align='center' label="序号" :index="indexMethod"></el-table-column>
        <el-table-column prop="simId" label="sim卡号" align="center" min-width='130'></el-table-column>
        <el-table-column prop="iccId" label="ICCID" align="center" min-width='170'></el-table-column>
        <el-table-column prop="serName" label="设备名称" :show-overflow-tooltip="true" align="center" min-width='100'></el-table-column>
        <el-table-column prop="deptName" label="所属库区" :show-overflow-tooltip="true" align="center" min-width='100'></el-table-column>
        <el-table-column label="设备类型" align="center" min-width='110' :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <dict-tag :options="dict.type.ser_type" :value="scope.row.serType"/>
          </template>
        </el-table-column>
        <el-table-column prop="used" label="本月已用(MB)" align="center" min-width='100'></el-table-column>
        <el-table-column prop="canused" label="本月可用(MB)" align="center" min-width='100'></el-table-column>
        <el-table-column prop="surplus" label="剩余流量(MB)" align="center" min-width='100'></el-table-column>
        <el-table-column prop="autoName" label="套餐名称" align="center" min-width='140' :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="message" label="网卡状态" align="center" min-width='100'></el-table-column>
        <el-table-column label="卡有效期" align="center" min-width='100' sortable>
          <template slot-scope="scope">
            <span :class='scope.row.expire?"error":""'>{{scope.row.expiredAt}}</span>
          </template>
        </el-table-column>
        <el-table-column label="官方实名状态" align="center" min-width='100'>
          <template slot-scope="scope">
            <dict-tag :options="dict.type.sim_ispick" :value="scope.row.isPick"/>
          </template>
        </el-table-column>
        <el-table-column label="认证状态" align="center" min-width='100'>
          <template slot-scope="scope">
            <dict-tag :options="dict.type.sim_cstatus" :value="scope.row.cStatus"/>
          </template>
        </el-table-column>
        <el-table-column label="开机状态" align="center" min-width='100'>
          <template slot-scope="scope">
            <dict-tag :options="dict.type.sim_gprs_status" :value="scope.row.gprsStatus"/>
          </template>
        </el-table-column>
        <el-table-column label="在线状态" align="center" min-width='100'>
          <template slot-scope="scope">
            <dict-tag :options="dict.type.sim_power_status" :value="scope.row.powerStatus"/>
          </template>
        </el-table-column>
        <el-table-column label="激活状态" align="center" min-width='100'>
          <template slot-scope="scope">
            <dict-tag :options="dict.type.sim_display_status" :value="scope.row.displayStatus"/>
          </template>
        </el-table-column>
        <el-table-column prop="jingtime" label="静默时间" align="center" min-width='100'></el-table-column>
        <el-table-column prop="shouchongTime" label="首充时间" align="center" min-width='100'></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="pageChange"
        :current-page='selectCard.pageNum'
        :page-sizes="[15, 30, 45, 60]"
        :page-size="selectCard.pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        class='pagination'
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from '../utils/requst.js'
import {saveAs} from 'file-saver'
export default {
  props:{
    width:Number,
    height:Number
  },
  dicts:[
    'ser_type',
    'sim_state',
    'sim_display_status',
    'sim_power_status',
    'sim_gprs_status',
    'sim_ispick',
    'sim_cstatus'
  ],
  data(){
    return{
      selectCard:{
        pageNum:1,
        pageSize:15,
        orderByColumn:'expiredAt',
        simId:'',
        iccId:'',
        deptName:'',
        expiredAt:'',
        state:''
      },
      cardList:[],
      total:0,
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        },{
          text: '最近一周',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        },{
          text: '最近一个月',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', date);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 90);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  created(){
    this.getCardList();
  },
  methods:{
    getCardList(){
      axios.get('/system/sim/list',{params:this.selectCard}).then(res=>{
        if(res){
          this.cardList=res.rows;
          this.total=res.total;
        }
      })
    },
    exportCard(){
      axios.post('/system/sim/export',{},{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob'
      }).then(res=>{
        const blob = new Blob([res])
        saveAs(blob, `物联网卡信息表.xlsx`)
      })
    },
    indexMethod(index){
      return (this.selectCard.pageNum-1)*this.selectCard.pageSize+index+1
    },
    pageChange(val){
      this.selectCard.pageNum=val;
      this.getCardList();
    },
    handleSizeChange(val){
      this.selectCard.pageSize=val;
      this.getCardList();
    },
  }
}
</script>

<style lang="scss" scoped>
.ioTCard{
  ::v-deep .cell{
    line-height: 32px
  }
}
</style>


