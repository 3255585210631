<template>
  <div class='wrap between' :style='`height:${height-12}px`'>
    <div class='head'>
      <el-form :inline="true" class="demo-form-inline" :model="selectParameter">
        <el-form-item label="参数名称">
          <el-input v-model="selectParameter.configName" clearable></el-input>
        </el-form-item>
        <el-form-item label="参数键名">
          <el-input v-model="selectParameter.configKey" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click='getParameter' icon="el-icon-search">确定</el-button>
        </el-form-item>
      </el-form>
      <div class='btnWrap'>
        <div class='btn' @click="addParameter">
          <svg-icon icon-class="add" class='icon'/>
          添加参数
        </div>
        <div class='btn' @click="refreshCache">
          <svg-icon icon-class="clear" class='icon'/>
          清除缓存
        </div>
      </div>
    </div>
    <div class='primarys' :style='`height:${height-82}px`'>
      <el-table
        :data="parameterList"
        ref="parameter-table"
        :style="`min-width:967px;width:${width-40}px;margin:-1px`"
        :header-cell-style='{"background":"#F5F9FA"}'
        :max-height="height-80"
        id='parameterExcel'
        border>
        <el-table-column type="index" width="60" align='center' label="序号" :index="indexMethod"></el-table-column>
        <el-table-column prop="configName" label="参数名称" align="center" min-width='100' :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="configKey" label="参数键名" align="center" min-width='100' :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="configValue" label="参数键值" align="center" min-width='100' :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="configType" label="系统内置" align="center" min-width='100'>
          <template slot-scope="scope">
            <span :class='scope.row.configType=="Y"?"blue":"offLine"'>{{scope.row.configType=='Y'?"是":"否"}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center" min-width='150' :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" min-width='150' sortable></el-table-column>
        <el-table-column 
          label="操作" 
          min-width="150" 
          fixed='right'
          align='center'>
          <template slot-scope="scope">
            <el-button @click.native.prevent="editParameter(parameterList[scope.$index].configId)" type="text" size="small">修改参数</el-button>
            <el-button @click.native.prevent="delParameter(parameterList[scope.$index].configId)" type="text" size="small">删除参数</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        class='pagination'
        @size-change="handleSizeChange"
        :page-sizes="[15, 30, 45, 60]"
        :total="total"
        :page-size='selectParameter.pageSize'
        :current-page='selectParameter.pageNum'
        @current-change='pageChange'>
      </el-pagination>
    </div>
    <el-dialog :title="title" :visible.sync="parameterShow"
      :close-on-click-modal='false'
      width='780px'
      :custom-class='"dialog-setStyle"'>
      <div class='formWrap'>
        <el-form ref="parameterInfo" :model="parameterInfo" label-width="80px" :rules="parameterRole">
          <div class='dialog-setWrap'>
            <el-form-item label="参数名称" prop='configName'>
              <el-input v-model="parameterInfo.configName" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="参数键名" prop="configKey">
              <el-input v-model="parameterInfo.configKey" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="参数键值" prop='configValue'>
              <el-input v-model="parameterInfo.configValue" @input='$forceUpdate()'></el-input>
            </el-form-item>
            <el-form-item label="系统内置" class='radioWrap' style='width:360px'>
              <el-radio-group v-model="parameterInfo.configType">
                <el-radio label="Y">是</el-radio>
                <el-radio label="N">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="备注">
               <el-input type="textarea"  v-model="parameterInfo.remark" @input='$forceUpdate()'></el-input>
            </el-form-item>
          </div>
          <el-form-item class='saveBtn'>
            <el-button type="primary" @click="saveParameter('parameterInfo')">保存</el-button>
            <el-button type="info" @click="parameterShow=false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog 
      :visible.sync="delTip"
      :close-on-click-modal='false'
      width='400px'
      top='300px'
      :custom-class='"dialog-setStyle"'>
      <div class='tipWrap'>
        <div class='tipTitle'>
          <i class='el-icon-warning'></i>
          温馨提示
        </div>
        <div class='tipContent'>此操作将永久删除该参数信息，请谨慎操作，是否继续？</div>
      </div>
      <div class='saveBtn viewBtn'>
        <el-button type="primary" @click="sendDel">删除</el-button>
        <el-button type="info" @click="delTip=false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../utils/requst.js'
export default {
  props:{
    width:Number,
    height:Number
  },
  data(){
    return{
      selectParameter:{
        pageNum:1,
        pageSize:15,
        isAsc:'desc',
        orderByColumn:'configId',
        configName:'',
        configKey:''
      },
      configId:null,
      parameterList:[],
      total:0,
      title:"",
      parameterInfo:{
        configType:'Y'
      },
      isAdd:false,
      parameterShow:false,
      delTip:false,
      parameterRole:{
        configName: [
          { required: true, message: "参数名称不能为空", trigger: "blur" }
        ],
        configKey: [
          { required: true, message: "参数键名不能为空", trigger: "blur" }
        ],
        configValue: [
          { required: true, message: "参数键值不能为空", trigger: "blur" }
        ]
      }
    }
  },
  created(){
    this.getParameter()
  },
  methods:{
    getParameter(){
      axios.get('/system/config/list',{params:this.selectParameter}).then(res=>{
        if(res){
          this.total=res.total;
          this.parameterList=res.rows;
        }
      })
    },
    addParameter(){
      this.isAdd=true;
      this.parameterShow=true;
      this.title='添加参数'
    },
    editParameter(configId){
      axios.get(`/system/config/${configId}`).then(res=>{
        if(res){
          this.isAdd=false;
          this.parameterShow=true;
          this.title='修改参数';
          this.parameterInfo=res.data;
        }
      })
    },
    delParameter(configId){
      this.delTip=true;
      this.configId=configId;
    },
    sendDel(){
      axios.delete(`/system/config/${this.configId}`).then(data=>{
        if(data){
          this.getParameter();
          this.delTip=false;
          this.$message({
            type: 'success',
            message: '删除成功!',
            duration:1000
          });
        }
      })
    },
    refreshCache(){
      axios.delete('/system/config/refreshCache').then(data=>{
        if(data){
          this.$message({
            type: 'success',
            message: '清除成功!',
            duration:1000
          });
        }
      })
    },
    saveParameter(obj){
      this.$refs[obj].validate((valid)=>{
        if(valid){
          if(this.isAdd){
            axios.post('/system/config',this.parameterInfo).then(data=>{
              if(data){
                this.getParameter()
                this.$message({
                  type: 'success',
                  message: '添加成功!',
                  duration:1000
                });
                this.parameterShow=false;
              }
            })
          }else{
            axios.put('/system/config',this.parameterInfo).then(data=>{
              if(data){
                this.getParameter()
                this.$message({
                  type: 'success',
                  message: '修改成功!',
                  duration:1000
                });
                this.parameterShow=false;
              }
            })
          }
        }else{
          return false
        }
      })
    },
    indexMethod(index){
      return (this.selectParameter.pageNum-1)*this.selectParameter.pageSize+index+1
    },
    pageChange(val){
      this.selectParameter.pageNum=val;
      this.getParameter()
    },
    handleSizeChange(val){
      this.selectParameter.pageSize=val;
      this.getParameter()
    }
  }
}
</script>

<style lang="scss" scoped>
.between{
  .dialog-setStyle{
    .dialog-setWrap{
      justify-content: space-between !important;
    }
  }
}
</style>
